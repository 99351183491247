angular.module('lite', [
    'ngResource',
    'ngRoute',
    'ngSanitize',
    'ngTouch',
    'barney'
])
.constant('EventBus', new JsEventbus())
.config(function($compileProvider, $routeProvider, $sceDelegateProvider, $httpProvider, BarneyConfigProvider, BarneyDictProvider, $injectorProvider){

    /*********************
    *** INITIALIZATION ***
    *********************/
    BarneyConfigProvider.init({
        config: CONFIG
    });

    BarneyDictProvider.init({
        dict: DICTIONARY,
        showKey: BarneyConfigProvider.get('IS_STAGING') ? 'missing' : 'default'
    });

    JsLogger.init({
        level: 'log',
        enable: true
    });

    JsStorage.init({
        type: 'cookie'
    });

    var jsDomainWithelist = BarneyConfigProvider.get('JS_DOMAIN') + '**';

    if (jsDomainWithelist.indexOf(window.location.protocol) < 0) {
        jsDomainWithelist = window.location.protocol + jsDomainWithelist;
    }

    $sceDelegateProvider.resourceUrlWhitelist([
        'self',
        jsDomainWithelist,
        '//*.motime.com/**'
    ]);

    if(BarneyConfigProvider.get('MFP_SPECIAL_DOMAIN')){
        console.log('MFP_SPECIAL_DOMAIN', BarneyConfigProvider.get('MFP_SPECIAL_DOMAIN'));        
        $sceDelegateProvider.trustedResourceUrlList([
            'self',
            'https://acq*.kidjo.tv/**'
        ]);
    }

    /****************************
     * TO OVERRIDE UO30 ROUTING
    ****************************/


    var overrideUO30CustomRouting = {};
    if(BarneyConfigProvider.get('ENABLE_NEWTON_USER') && BarneyConfigProvider.get('OVERRIDE_U030_CUSTOM_ROUTING')){
        overrideUO30CustomRouting = BarneyConfigProvider.get('OVERRIDE_U030_CUSTOM_ROUTING');
    }

    /**********************
     * ROUTING
    **********************/
    var customRoute = BarneyConfigProvider.get('CUSTOM_ROUTING');
    for(var k in customRoute){
        if (Object.prototype.hasOwnProperty.call(customRoute, k)){

            if(typeof overrideUO30CustomRouting[k] !== 'undefined'){
                customRoute[k] = overrideUO30CustomRouting[k];
            }

            customRoute[k].templateUrl = (function(route){
                var prefix = BarneyConfigProvider.get('IS_JENKINS_DEPLOYED') ? BarneyConfigProvider.get('JS_PREFIX_APPS') + 'dist/' : BarneyConfigProvider.get('JS_STAGE_URL');

                if(route.logged_template){
                    return prefix + route.logged_template;
                } else if(route.unlogged_template){
                    return prefix + route.unlogged_template;
                }

            }(customRoute[k]));
            $routeProvider.when(customRoute[k].route, customRoute[k]);
        }
    }
    $routeProvider.otherwise({ redirectTo: '/' });

        /*******************
        ***   SANITIZE   ***
        *******************/

    $compileProvider.aHrefSanitizationWhitelist(/^\s*(file|http|https?|javascript):/);

}).run(function($rootScope, $location, BarneyBrowser, BarneyConfig, BarneyDict, BarneyMeta, GoogleManager, MetaTitleManager, Api, UserObj, EventBus){

    var dataLayerDefault = {};

    /**********************
     * BROWSER
    **********************/

    BarneyBrowser.init();

    /**************************
    ***  INIT SNOWPLOW ***
    **************************/

    // Init SnowPlow 
    if (window.snowplow !== undefined && BarneyConfig.get('SNOWPLOW_TRACKING_ANALYTICS')) {
        const collectorUrl = window.location.origin.includes('https') ? window.location.origin.replace('https://', '') : window.location.origin.replace('http://', '');
        const collector = window.location.origin.includes('2') ? 'com-digitalvirgo-prod1.mini.snplow.net' : collectorUrl;
        var countrySP = window.location.origin;
        var arrayStringCountry = countrySP.split('.');
        var appId = BarneyDict.get('SNOWPLOW_APP_ID') || '';
        var optionsSp = window.location.origin.includes('2') ? {
            appId: appId
        } : {
            appId: appId,
            discoverRootDomain: true,
            cookieName: '_snp_',
            contexts: {
                performanceTiming: true                        
            }
        };
        window.snowplow('newTracker', 'sp1', collector, optionsSp);
    }    


    /**********************
     * NEWTON ADAPTER
    **********************/
    // set Newton init properties
    var newtonInitProperties = {};//Cat.getCatParams();
    var campaignRecognitionShare = BarneyBrowser.getQueryParams().campaignRecognitionShare;

    newtonInitProperties.environment = ''; //StargateSvc.isHybrid() ? 'Hybrid ' : '';
    newtonInitProperties.environment += BarneyConfig.get('DEVICE_OS') ? BarneyConfig.get('DEVICE_OS') : 'webapp';

    if(typeof campaignRecognitionShare !== 'undefined') {
        newtonInitProperties.cr = campaignRecognitionShare;
        $rootScope.campaignRecognition = campaignRecognitionShare;
    }


    var initGoogleTagManager = function(){
      
        if(BarneyConfig.get('GOOGLE_TAG_ENABLE')){
            
            Api.getNetworkProperty().$promise.then(function (resp) {

                var userLogged = function(H) {
                    if (NewtonAdapter.isUserLogged()) {
                        return BarneyDict.get('WEBAPP_USER_IDENTIFIED' + H);
                    } else {
                        return BarneyDict.get('WEBAPP_USER_NOT_IDENTIFIED' + H);
                    }
                };
                
                dataLayerDefault = {
                    'Tipo de red': resp.networkType ? BarneyDict.get('WEBAPP_TYPE_CONNECTION_3G') : BarneyDict.get('WEBAPP_TYPE_CONNECTION_WIFI'),
                    'Seccion de navegacion': BarneyDict.get('WEBAPP_NAVIGATION_SECTION'),
                    'Seccion de navegacion /h': BarneyDict.get('WEBAPP_NAVIGATION_SECTION_H'),
                    'Tipo de pagina': BarneyDict.get('WEBAPP_NAVIGATION_SERVICE_OTHER'),
                    'Tipo de pagina /h': BarneyDict.get('WEBAPP_NAVIGATION_SERVICE_OTHER_H'),
                    Vertical: BarneyDict.get('WEBAPP_VERTICAL_CATEGORY'),
                    'Vertical /h': BarneyDict.get('WEBAPP_VERTICAL_CATEGORY_H'),
                    'Subcategoria tematica': BarneyDict.get('WEBAPP_VERTICAL_SUBCATEGORY'),
                    'Subcategoria tematica /h': BarneyDict.get('WEBAPP_VERTICAL_SUBCATEGORY_H'),
                    Servicio: BarneyDict.get('WEBAPP_SERVICE_NAME'),
                    'Servicio /h ': BarneyDict.get('WEBAPP_SERVICE_NAME_H'),
                    Contenido: BarneyDict.get('WEBAPP_NO_APPLICABLE_CONTENT'),
                    'Contenido /h': BarneyDict.get('WEBAPP_NO_APPLICABLE_CONTENT_H'),
                    'Proveedor del servicio': BarneyDict.get('WEBAPP_SERVICE_PROVIDER'),
                    'Proveedor del servicio /h': BarneyDict.get('WEBAPP_SERVICE_PROVIDER_H'),
                    'Proveedor tecnico del servicio': BarneyDict.get('WEBAPP_TECHNICAL_PROVIDER'),
                    'Proveedor tecnico del servicio /h': BarneyDict.get('WEBAPP_TECHNICAL_PROVIDER_H'),
                    'Estado de suscripcion al servicio': GoogleManager.getGtmUserStatus(UserObj.isLogged(), ''),
                    'Estado de suscripcion al servicio /h': GoogleManager.getGtmUserStatus(UserObj.isLogged(), '_H'),
                    'Estado de identificación de usuario': userLogged(''),
                    'Estado de identificación de usuario /h': userLogged('_H')
                };
                
                GoogleManager.init(dataLayerDefault);

            });
        }
    };

    /***********************
    *** GOOGLE ANALYTICS ***
    ***********************/

    JsAnalytics.init({
        enabled: BarneyConfig.get('ENABLE_ANALYTICS'),
        logger: JsLogger,
        dimensions: {
            UserStatus: 1,
            AccessType: 2,
            SocialNetworkSource: 3,
            SubscriptionDate: 4,
            Valuable: 5,
            Country: 6,
            ProductTitle: 7,
            Action: 8,
            Environment: 9,
            Ref: 10,
            PaymentType: 11,
            'User ID': 12
        }
    });


    /************************************************
    *** get conf pages with GTM  + Meta setting  ****
    ************************************************/

    var gtmPagesConf = [];
    var gtmPagesPath = [];

    var metaTitleAccountConf = [];
    var metaTitleAccountPath = [];
    var metaCustomRouter = BarneyConfig.get('CUSTOM_ROUTING') || [];

    if(BarneyConfig.get('GTM_PAGES')){
        gtmPagesConf = BarneyConfig.get('GTM_PAGES');
        // set the route path page
        for(var i = 0; i < gtmPagesConf.length; i++){
            if(metaCustomRouter[gtmPagesConf[i]] && metaCustomRouter[gtmPagesConf[i]].route){
                gtmPagesPath.push(metaCustomRouter[gtmPagesConf[i]].route);
            }
        }
    }

    if(BarneyConfig.get('META_ACCOUNT_PAGES')){
        metaTitleAccountConf = BarneyConfig.get('META_ACCOUNT_PAGES');
        // set the route path page
        for(var y = 0; y < metaTitleAccountConf.length; y++){
            var temp = metaTitleAccountConf[y].replace('ROUTER_', '');
            if(metaCustomRouter[temp]){
                metaTitleAccountPath.push(metaCustomRouter[temp].route);
            }
        }
    }


    if(BarneyConfig.get('ENABLE_NEWTON_USER')){
        console.log('NEWTON USER');
        useris.init({
            domain: BarneyConfig.get('DEST_DOMAIN'),
            country: BarneyConfig.get('REAL_COUNTRY'),
            enableAliasCheck: BarneyConfig.get('ENABLE_ALIAS_URL'),
            alaisCheckUrl: BarneyConfig.get('ALIAS_URL'),
            disablePinInput: BarneyConfig.get('HIDE_LOGINPIN'),
            hybridnews: 0,
            store_type: '',
            apikey: BarneyConfig.get('MOTIME_API_KEY'),
            enableGoogleCheckbox: BarneyConfig.get('SHOW_GOOGLE_CHECKBOX'),
            emailIdentityEnabled: BarneyConfig.get('IDENTITY_EMAIL_ENABLED'),
            identityFbEnabled: BarneyConfig.get('IDENTITY_FB_ENABLED'),
            redirectToMipRegisterAtStart: BarneyConfig.get('REDIRECT_TO_MIP_REGISTER_AT_START'),
            regexObject: {
                msisdn: BarneyConfig.get('COUNTRY_REGEXP_VALIDATE_PLATINUM_MSISDN'),
                pin: BarneyConfig.get('COUNTRY_REGEXP_VALIDATE_PLATINUM_PIN'),
                password: BarneyConfig.get('REGEXP_VALIDATE_PASSWD') || '[a-zA-Z0-9]',
                // eslint-disable-next-line no-useless-escape
                email: BarneyConfig.get('REGEXP_VALIDATE_EMAIL') || '.+@.+\..+',
                generic: '.',
                genericPassword: '.'
            }
        });

        useris.PopupManager.init(BarneyConfig.get('USERIS_POPUP_CONFIGURATION'), function(resp){
            console.log('USERIS', 'Popup initialized', resp);
        });

        // (1) Newton init
        NewtonAdapter.init({
            logger: JsLogger,
            enable: BarneyConfig.get('ENABLE_NEWTON_TRACKING'),
            waitLogin: true,
            secretId: BarneyConfig.get('NEWTON_SECRETID'),
            properties: newtonInitProperties,
            config: {
                whiteLabel: BarneyConfig.get('WHITE_LABEL'),
                isInternational: BarneyConfig.get('IS_INTERNATIONAL') ? true : false,
                getSnowPlowInstance: function () {
                    return window.snowplow || null;
                }
            }
        }).then(function(){
            // (2) User ini
            UserObj.init(function(resp){
                JsLogger.info('Userrrrrrrr ', resp);
                if(NewtonAdapter.isUserLogged()){
                    //SUPENDED CASE 
                    if (resp.suspended) {
                        $rootScope.suspended = resp.suspended;
                        console.log('INFO USERS', resp);
                        $rootScope.showSuspendedModal = true;
                    }           
                    if(!JsStorage.get('hasSeenSecondaryHeader') && BarneyConfig.get('SHOW_TEXT_HEADER')){
                        $rootScope.showSecondaryHeaderSection = true;
                    }

                    if (JsStorage.get('user-login-show-feedback', { type: 'cookie' })) {
                        useris.PopupManager.showUserFeedback({
                            type: 'positive',
                            feedback: {
                                message: BarneyDict.get('WEBAPP_UO30_FEEDBACK_LOGIN')
                            }
                        });

                        JsStorage.delete('user-login-show-feedback', { type: 'cookie' });
                    }

                } else if (JsStorage.get('user-logout-show-feedback', { type: 'cookie' })){

                    if(BarneyConfig.get('SHOW_TEXT_HEADER')){
                        $rootScope.showSecondaryHeaderSection = true;
                    }

                    useris.PopupManager.showUserFeedback({
                        feedback: {
                            message: BarneyDict.get('WEBAPP_UO30_FEEDBACK_LOGOUT')
                        },
                        type: 'positive'
                    });

                    JsStorage.delete('user-logout-show-feedback', { type: 'cookie' });
                }

                JsAnalytics.setDimension({
                    UserStatus: UserObj.isLogged() ? 'logged' : 'not logged',
                    AccessType: UserObj.isLogged() ? 'premium' : 'free',
                    Country: BarneyConfig.get('TLD'),
                    Environment: 'webapp'
                });

                initGoogleTagManager();

            });

            // (3) Newton login
            NewtonAdapter.login({
                logged: false
            });

            // (4) Listen Newton login/logout
            NewtonAdapter.setUserStateChangeListener({
                onLoginStateChange: function(){
                    UserObj.init(function(resp){
                        EventBus.trigger('User-State-Changed');
                        if(NewtonAdapter.isUserLogged()){
                            EventBus.trigger('User-Login');
                            EventBus.trigger('User-Login-Menu', true);

                        } else {
                            EventBus.trigger('User-Logout');
                            EventBus.trigger('User-Logout-Menu', true);

                        }
                    });
                }
            });
        });
    } else {
        NewtonAdapter.init({
            logger: JsLogger,
            enable: BarneyConfig.get('ENABLE_NEWTON_TRACKING'),
            waitLogin: true,
            secretId: BarneyConfig.get('NEWTON_SECRETID'),
            properties: {
                environment: BarneyConfig.get('DEVICE_OS') || 'webapp'
            },
            config: {
                whiteLabel: BarneyConfig.get('WHITE_LABEL'),
                isInternational: BarneyConfig.get('IS_INTERNATIONAL') ? true : false
            }
        }).then(function(){
            UserObj.init(function(){
                if(UserObj.isLogged()){

                    if(!JsStorage.get('hasSeenSecondaryHeader') && BarneyConfig.get('SHOW_TEXT_HEADER')){
                        $rootScope.showSecondaryHeaderSection = true;
                    }

                    NewtonAdapter.login({
                        logged: true,
                        type: 'custom',
                        userId: BarneyConfig.get('USER_DATA.USER'),
                        userProperties: {
                            msisdn: BarneyConfig.get('USER_DATA.MSISDN') || '',
                            white_label_id: BarneyConfig.get('WHITE_LABEL') || '',
                            country: BarneyConfig.get('TLD') || '',
                            real_country: BarneyConfig.get('REAL_COUNTRY') || ''
                        }
                    });

                } else {
                    if(BarneyConfig.get('SHOW_TEXT_HEADER')){
                        $rootScope.showSecondaryHeaderSection = true;
                    }

                    NewtonAdapter.login({
                        logged: false
                    });

                }

                JsAnalytics.setDimension({
                    UserStatus: UserObj.isLogged() ? 'logged' : 'not logged',
                    AccessType: UserObj.isLogged() ? 'premium' : 'free',
                    Country: BarneyConfig.get('TLD'),
                    Environment: 'webapp'
                });

                initGoogleTagManager();

            });
        });
    }

    if(BarneyConfig.get('SETTRACK_PARAMS')){
        NewtonAdapter.setLogView(BarneyConfig.get('SETTRACK_PARAMS'));
    }

    $rootScope.country = BarneyConfig.get('TLD');

    $rootScope.$on('$routeChangeSuccess', function(event, current, previous){
        var currentRoute = current.$$route;

        NewtonAdapter.trackPageview({
            properties: {
                url: $location.path(),
                page_path: $location.absUrl(),
                page_title: docomoUtils.checkObject('$$route.route', current) || '',
                http_referrer: document.referrer,
                previous_page: docomoUtils.checkObject('$$route.route', previous) || ''
            }
        });

        if (BarneyConfig.get('SERVICE_CLOSED_TO_UNIDENTIFIED')){
            var closedToUnidentified = BarneyConfig.get('SERVICE_CLOSED_TO_UNIDENTIFIED');
            var isIdentified = BarneyConfig.get('SERVICE_OPEN_TO_PROSPECTS') ? BarneyConfig.get('HAS_OPERATOR') : false;
            var closedRoutes = BarneyConfig.get('CLOSED_TO_UNIDENTIFIED_ROUTES');
            if (closedRoutes.indexOf(currentRoute.route) === -1){
                closedToUnidentified = false;
            }
            if (BarneyConfig.get('SERVICE_CLOSED_TO_UNIDENTIFIED_REDIRECT') && (closedToUnidentified && !NewtonAdapter.isUserLogged() && !isIdentified)){
                window.location.href = BarneyConfig.get('SERVICE_CLOSED_TO_UNIDENTIFIED_REDIRECT') || '#!/account';
            } else {
                $rootScope.showConnectionModal = closedToUnidentified && !NewtonAdapter.isUserLogged() && !isIdentified ? true : false;
            }
        }
    });

    $rootScope.$on('$routeChangeSuccess', function(event, current, previous){
        JsAnalytics.trackPage({
            page: $location.path(),
            title: docomoUtils.checkObject('$$route.route', current) || ''
        });

        if(BarneyConfig.get('SHOW_TEXT_HEADER') && !BarneyConfig.get('SECONDARY_HEADER_SECTION_ALWAYS_VISIBLE') && UserObj.isLogged()){
            if($rootScope.showSecondaryHeaderSection && JsStorage.get('hasSeenSecondaryHeader')){
                $rootScope.showSecondaryHeaderSection = false;
            }

            if(!JsStorage.get('hasSeenSecondaryHeader')){
                JsStorage.set('hasSeenSecondaryHeader', true);
            }
        }
    });
    /**********************
     * META TAG
    **********************/

    BarneyMeta.init({
        title: BarneyDict.get('SEO_WEBAPP_META_TITLE_LITE'),
        description: BarneyDict.get('SEO_WEBAPP_META_DESCRIPTION_LITE'),
        ogType: BarneyDict.get('SEO_WEBAPP_META_OGTAGS_TYPE'),
        ogTitle: BarneyDict.get('SEO_WEBAPP_META_OGTAGS_HOME_LITE'),
        ogDescription: BarneyDict.get('SEO_WEBAPP_META_OGTAGS_HOME_DESC_LITE'),
        ogUrl: $location.absUrl(),
        ogSiteName: BarneyDict.get('SEO_WEBAPP_META_OGTAGS_NAME_HOME_LITE'),
        ogImage: BarneyConfig.get('DEFAULT-OG-IMAGE'),
        class: ''
    });

    $rootScope.$on('$routeChangeStart', function(event, current, previous){
        var currentRoute = current.$$route;
        BarneyMeta.revert();
        

        if(metaTitleAccountPath.length > 0 && metaTitleAccountPath.indexOf(currentRoute.originalPath) !== -1){
            
            BarneyMeta.set({
                title: MetaTitleManager.getMetaTitle({
                    maskType: 'list',
                    content: BarneyDict.get('SEO_WEBAPP_HEAD_TITLE_ACCOUNT')
                }),
                class: docomoUtils.checkObject('$$route.class', current) || ''
            },
                function(){
                    if(BarneyConfig.get('GOOGLE_TAG_ENABLE')){
                        GoogleManager.set({
                            'Tipo de pagina': BarneyDict.get('WEBAPP_NAVIGATION_SERVICE_OTHER'),
                            'Tipo de pagina /h': BarneyDict.get('WEBAPP_NAVIGATION_SERVICE_OTHER'),
                            Contenido: BarneyDict.get('WEBAPP_NO_APPLICABLE_CONTENT'),
                            'Contenido /h': BarneyDict.get('WEBAPP_NO_APPLICABLE_CONTENT_H'),
                            event: 'changePage'
                        });
                    }
                }
            );
        } else {
            
            BarneyMeta.set({
                class: docomoUtils.checkObject('$$route.class', current) || ''
            });
        }
    });

    function setUserStatus() {
        $rootScope.userLogged = UserObj.isLogged();
    }
    setUserStatus();
    EventBus.on('User-State-Changed', setUserStatus);
});

'use strict';

angular.module('lite').service('Api',
function($resource, BarneyConfig){

    function transformResponse(key){
        return function(data){
            var object = angular.fromJson(data);
            JsLogger.log('API', key, object);
            return object;
        };
    }

    return $resource('', {}, {
        smartsection: {
            method: 'GET',
            url: BarneyConfig.get('MOA_API_SMART_SECTION'),
            transformResponse: transformResponse('MOA_API_SMART_SECTION')
        },
        createPony: {
            method: 'GET',
            url: BarneyConfig.get('MOA_API_CREATEPONY'),
            cache: false,
            isArray: false,
            transformResponse: transformResponse('MOA_API_CREATEPONY')
        },
        set: {
            method: 'JSONP',
            url: BarneyConfig.get('MFP_API_URL') + 'put',
            cache: false,
            transformResponse: transformResponse('MOA_API_CREATEPONY')
        },
        getAliasForMsisdn: {
            method: 'GET',
            url: BarneyConfig.get('ALIAS_CHECK_URL'),
            isArray: false,
            cache: false,
            withCredentials: false,
            transformResponse: transformResponse('ALIAS_CHECK_URL')
        },
        getNetworkProperty: {
            method: 'GET',
            url: BarneyConfig.get('DEST_DOMAIN') + BarneyConfig.get('NETWORK_INFO_API_URL'),
            isArray: false,
            cache: false,
            transformResponse: transformResponse('NETWORK_INFO_API_URL')
        }
    });
});

/*eslint-disable */
angular.module('lite').factory('GoogleManager',
    function($q, BarneyConfig, BarneyDict) {

        return {
            defer: $q.defer(),

            init: function(initDL){
                console.log(['API', 'Header', '1', initDL]);
                if(BarneyConfig.get('GOOGLE_TAG_ENABLE') && BarneyConfig.get('GOOGLE_TAG_ID') && BarneyConfig.get('ENABLE_DATA_LAYER_MANAGER')){
                    window.dataLayer.push(initDL);
                    console.log(['API', 'Header', '2', initDL]);
																				(function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
																				new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
																				j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
																				'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
																				})(window,document,'script','dataLayer',BarneyConfig.get('GOOGLE_TAG_ID'));
                }
																
                this.defer.resolve(initDL);
            },

            getGtmUserStatus: function(isLogged, hString) {
                var operator = JsStorage.get('operator', { type: 'cookie' });

                if (typeof operator === 'undefined') {
                    return BarneyDict.get('WEBAPP_SERVICE_STATE_UNKNOWN' + hString);
                } else if (isLogged) {
                    return BarneyDict.get('WEBAPP_SERVICE_STATE_LOGGED' + hString);
                } else if (!isLogged) {
                    return BarneyDict.get('WEBAPP_SERVICE_STATE_UNLOGGED' + hString);
                }

                return BarneyDict.get('WEBAPP_SERVICE_STATE_UNKNOWN' + hString);
            },

            set: function(setDL){
                console.log(['API', 'Header', '3', setDL]);
                this.defer.promise.then(function(defaultDataLayer){
                    if(BarneyConfig.get('GOOGLE_TAG_ENABLE') && BarneyConfig.get('GOOGLE_TAG_ID') && BarneyConfig.get('ENABLE_DATA_LAYER_MANAGER')){
                        var newDataLayer = {};
                        for(var ddlKey in defaultDataLayer){
                            newDataLayer[ddlKey] = defaultDataLayer[ddlKey];
                        }
                        for(var sdlKey in setDL){
                            newDataLayer[sdlKey] = setDL[sdlKey];
                        }
																								
																								window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push(newDataLayer);
                        console.log(['API', 'Header', '4', newDataLayer]);


                    }
                });
            }
        };
    }
);
/*eslint-enable */

'use strict';

angular.module('lite').controller('homeKidjo',
function($scope, BarneyConfig, UserObj, Mfp){

    $scope.mfp = function (storeType){
        Mfp.setAndRedirect(storeType);
    };

    // Track Page view of snowplow
    if (window.snowplow !== undefined && BarneyConfig.get('SNOWPLOW_TRACKING_ANALYTICS')){
        window.snowplow('trackPageView', {
            context: [{
                schema: 'iglu:com.dgp/page_category/jsonschema/1-0-0',
                data: {
                    kind_of_page: 'servicehomepage'
                }
            }]
        });
    }


    $scope.kidjoLink = '';

    if(UserObj.isLogged() && BarneyConfig.get('ENABLE_NEWTON_USER')){
        UserObj.getProperty('kidjo_action_url', function(resp){
            $scope.kidjoLink = resp;
        });
    }

});

'use strict';

angular.module('lite').directive('header', 
function(Utility, Mfp, UserObj, BarneyConfig, $sce){
    
    return {
        restrict: 'AC',
        scope: {},
        templateUrl: Utility.getModulesTemplate('header'),
        link: function($scope, $element, $attr){

            if(UserObj.isLogged()){
                $scope.userLink = BarneyConfig.get('ACCOUNT_LINK_LOGGED');
                UserObj.getProperty('fake', function(fakeUser){
                    console.log(fakeUser);
                    if(BarneyConfig.get('ENABLE_NEWTON_USER') && fakeUser === 'fake'){
                        $scope.userLink = BarneyConfig.get('ACCOUNT_LINK');
                    }
                });
            } else if(BarneyConfig.get('ADD_DOMAIN_TO_ACCOUNT_LINK')){
                $scope.userLink = BarneyConfig.get('DEST_DOMAIN') + BarneyConfig.get('ACCOUNT_LINK');
            } else {
                $scope.userLink = BarneyConfig.get('ACCOUNT_LINK');
            }
            
            $scope.showHeaderText = BarneyConfig.get('SHOW_SECONDARY_HEADER_SECTION');
            $scope.showMultilanguage = BarneyConfig.get('SHOW_MULTIPLE_LANGUAGE');
            $scope.dropdownOpen = false; // Per gestire l'apertura del dropdown

            if ($scope.showMultilanguage) {
                $scope.languageList = BarneyConfig.get('MULTILANGUAGE_LIST') || [];
                $scope.currentLanguage = BarneyConfig.get('LANGUAGE');
            
                $scope.languagePrefixes = $scope.languageList.map(function(lang) {
                    // Controlla se la lingua è selezionata e aggiungi <strong> per il grassetto
                    if (lang.IS_SELECTED === 1) {
                        return '<strong>' + lang.ID.toUpperCase() + '</strong>';
                    }
                    return lang.ID.toUpperCase(); // Altrimenti solo il nome della lingua
                }).join(' / ');
                
                // Usa $sce per permettere il rendering di HTML
                $scope.languagePrefixes = $sce.trustAsHtml($scope.languagePrefixes);                
            }

            // Funzione per aprire/chiudere il dropdown
            $scope.toggleDropdown = function() {
                $scope.dropdownOpen = !$scope.dropdownOpen;
            };

            // Funzione per selezionare una lingua
            $scope.selectLanguage = function(language) {
                if (!language.IS_SELECTED) {
                    window.location.href = window.location.origin + language._LANGURL_ABSOLUTE;
                }
                $scope.dropdownOpen = false; // Chiude il dropdown dopo la selezione
            };

            // Funzione per chiudere il dropdown se si clicca fuori
            document.addEventListener('click', function(event) {
                const selector = $element[0].querySelector('.language-selector');
                if (!selector.contains(event.target)) {
                    $scope.$apply(function() {
                        $scope.dropdownOpen = false;
                    });
                }
            });

            $scope.mfp = function(){
                Mfp.setAndRedirect();
            };
        }
    };
});
'use strict';

angular.module('lite').directive('footer', ['BarneyConfig', 'Utility', 'Mfp', '$sce', function(BarneyConfig, Utility, Mfp, $sce){
    return {
        restrict: 'E',
        scope: {},
        templateUrl: Utility.getModulesTemplate('footer'),
        link: function($scope, $element, $attr){

            $scope.mfp = function (storeType){
                Mfp.setAndRedirect(storeType);
            };

            $scope.links = FOOTER_LINKS;
            $scope.showMultilanguage = BarneyConfig.get('SHOW_MULTIPLE_LANGUAGE');
            $scope.verticalFooter = BarneyConfig.get('ENABLE_VERTICAL_FOOTER');

            if ($scope.showMultilanguage){
                $scope.languageList = BarneyConfig.get('MULTILANGUAGE_LIST') || [];
                $scope.currentLanguage = BarneyConfig.get('LANGUAGE');
            }

        }
    };
}]);
'use strict';

angular.module('lite').directive('cookieAlert',
function($document, BarneyConfig, Utility, $sce){
    return {
        restrict: 'E',
        scope: {},
        templateUrl: $sce.trustAsResourceUrl(Utility.getModulesTemplate('cookiealert')),
        link: function($scope, $element, $attr){

            var showPrivacy = !!BarneyConfig.get('SHOW_PRIVACY_BLOCK');
            var cookieExist = !!JsStorage.get('prvcflag', { type: 'cookie' });

            // Show/hide cookie alert
            $scope.show = (showPrivacy && !cookieExist);

            // Logger
            JsLogger.info('CookieAlert', {
                _show: $scope.show,
                showPrivacy: showPrivacy,
                cookieExist: cookieExist
            });

            $scope.close = function(){
                // Set prvcflag cookie
                JsStorage.set('prvcflag', true, { type: 'cookie' });

                // Hide cookie alert
                $scope.show = false;

                // Remove listener on scroll and overlay
                document.removeEventListener('scroll', $scope.close);
                if (angular.element(document.querySelector('#cookie-overlay'))){
                    angular.element(document.querySelector('#cookie-overlay')).remove();
                }
                $scope.$apply();
            };

            $element.ready(function(){
                if ($scope.show){
                    // close on scroll
                    if (BarneyConfig.get('CLOSE_COOKIE_ON_SCROLL')){
                        //remove the eventListener in the Close function as on line 76:
                        //  document.removeEventListener('scroll', $scope.closeAlert);
                        document.addEventListener('scroll', $scope.close, false);
                    }

                    // close on click outside the box
                    if (BarneyConfig.get('CLOSE_COOKIE_ON_EXTERNAL_CLICK')){
                        var cookieZindex = window.getComputedStyle(document.querySelector("[data-mipqa='privacyBox']"), null).getPropertyValue('z-index');
                        var cookieOverlay = angular.element('<div id="cookie-overlay"></div>');
                        $document.find('body').append(cookieOverlay);
                        cookieOverlay.css({
                            zIndex: cookieZindex - 1,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0
                        });
                        document.querySelector('#cookie-overlay').addEventListener('click', function(e){
                            e.stopPropagation();
                            $scope.close();
                            cookieOverlay.remove();
                        }, false);
                    }

                    // close on internal link click
                    if (BarneyConfig.get('CLOSE_COOKIE_ON_INTERNAL_LINK')){
                        var cookieLink = document.querySelector("[data-mipqa='privacyBox'] p a");
                        if (cookieLink){
                            var goToLink = cookieLink.getAttribute('href');
                            cookieLink.addEventListener('click', function(e){
                                e.preventDefault();
                                $scope.close();
                                location.href = goToLink;
                            }, false);
                        }
                    }
                }
            });

        }
    };
});

angular.module('lite').directive('consentManager',
    ['BarneyConfig', '$sce', '$document', 'Utility',
        function (Config, $sce, $document, Utility) {
            var prefix = Config.get('IS_JENKINS_DEPLOYED') ? Config.get('JS_PREFIX_APPS') + 'dist/' : Config.get('JS_STAGE_URL');
            return {
                restrict: 'E',
                templateUrl: $sce.trustAsResourceUrl(prefix + 'modules/consent/consent.html'),
                scope: {},
                link: function ($scope, $element, $attr) {

                    $scope.openPrefs = function () {
                        if (Didomi && Didomi.preferences) {
                            Didomi.preferences.show();
                        }
                    };

                }
            };
        }
    ]
);

'use strict';

angular.module('lite').controller('account', 
function($scope, $sce, $timeout, orderByFilter, Api){

    // init variables   
    $scope.active = -1;

    // first API call
    Api.smartsection({
        SUBSECTION: 'settings',
        CONTENT_ID: 0
    }).$promise.then(function(response){

        $scope.sections = orderByFilter(response.content, 'position');
        for(var k = 0; k < $scope.sections.length; k += 1){
            if($scope.sections[k].open_at_start){
                $scope.open($scope.sections[k], k);
                break;  
            }
        }
    });

    $scope.open = function(section, index){
        var smartsectionObject = {
            SUBSECTION: section.subsection,
            CONTENT_ID: section.content_id
        };

        if(section.content_id === 'smartstatus'){
            smartsectionObject.fromsettings = 1;
        }
        
        // active/deactive section
        if($scope.active !== index){
            $scope.active = index;
        } else {
            $scope.active = -1;
        }

        // fill text
        if(!$scope.sections[index].text){
            Api.smartsection(smartsectionObject).$promise.then(function(response){
                $scope.sections[index].text = $sce.trustAsHtml(response.content);
            });
        }
    };

});
'use strict';

angular.module('lite').controller('login',
    function($scope, $sce, $http, $q, BarneyConfig, BarneyBrowser, Api){

        var returnUrl = BarneyBrowser.getQueryParams().return_url || '/';

        var VALIDATION_PIN = new RegExp(BarneyConfig.get('COUNTRY_REGEXP_VALIDATE_PLATINUM_PIN')),
            VALIDATION_TEL = new RegExp(BarneyConfig.get('COUNTRY_REGEXP_VALIDATE_PLATINUM_MSISDN')),
            VALIDATION_EMAIL = new RegExp(BarneyConfig.get('REGEXP_VALIDATE_EMAIL')),
            VALIDATION_PASSWD = new RegExp(BarneyConfig.get('REGEXP_VALIDATE_PASSWD')),
            userType = 'not defined';

        var loginWithEmail = BarneyConfig.get('MOA_API_MIP_USER_MIPLOGIN');
        var aliasDefer = $q.defer();


        $scope.text = '';

        // initialize userform object
        $scope.userform = { username: '', password: '' };

        // get phone number from localStorage, if present
        if (!BarneyConfig.get('ENABLE_ALIAS_CHECK')) {

            if(JsStorage.isLocalStorageSupported()){
                $scope.userform.username = JsStorage.get('userform.username', { type: 'localstorage' }) || '';
            } else {
                $scope.userform.username = '';
            }

            // get phone number from query string, if present
            var msisdnQS = BarneyBrowser.getQueryParams().msisdn;
            if(typeof (msisdnQS) !== 'undefined' && msisdnQS !== 'true'){
                if(msisdnQS.indexOf(' ') === 0){
                    $scope.userform.username = msisdnQS.replace(' ', '+');
                } else {
                    $scope.userform.username = msisdnQS;
                }
            }
        }


        $scope.errorUserReq = false;
        $scope.errorUserPatt = false;
        $scope.errorPassReq = false;
        $scope.errorPassPatt = false;
        $scope.errorCheckbox = false;
        $scope.error401 = false;
        $scope.error403 = false;

        // on input change - validates the username type and changes passwd validation pattern accordingly
        $scope.watchType = function(){
            $scope.VALIDATION_USERNAME = (function() {
                if(VALIDATION_EMAIL.test(document.querySelector('#username').value) === true) {
                    userType = 'email';
                    $scope.VALIDATION_PASSWORD = VALIDATION_PASSWD;
                    return VALIDATION_EMAIL;
                } else {
                    userType = 'msisdn';
                    $scope.VALIDATION_PASSWORD = VALIDATION_PIN;
                    return VALIDATION_TEL;
                }
            })();
        };

        $scope.submitForm = function(form) {
            $scope.errorUserReq = false;
            $scope.errorUserPatt = false;
            $scope.errorPassReq = false;
            $scope.errorPassPatt = false;
            $scope.errorCheckbox = false;
            $scope.error401 = false;
            $scope.error403 = false;

            if (form.$valid) {
                if(userType !== 'email') {

                    if (BarneyConfig.get('ENABLE_ALIAS_CHECK')) {
                        var phoneNumber = '';
                        if ($scope.userform.username.indexOf('+') >= 0) {
                            phoneNumber = $scope.userform.username.replace('+', '');
                        } else {
                            phoneNumber = $scope.userform.username;
                        }

                        Api.getAliasForMsisdn({ MSISDN: phoneNumber }).$promise.then(function (resp) {
                            aliasDefer.resolve(resp.alias);
                        });
                    } else {
                        aliasDefer.resolve(false);
                    }

                    aliasDefer.promise.then(function(aliasResp){
                        Api.smartsection({
                            SUBSECTION: 'smartlogin',
                            CONTENT_ID: '',
                            username: aliasResp ? aliasResp : $scope.userform.username,
                            password: $scope.userform.password,
                            return_url: returnUrl
                        }, function (e) {
                            switch (e.status) {
                            case '302':
                                window.location.href = e.content;
                                break;
                            default:
                                $scope.text = $sce.trustAsHtml(e.content);
                                break;
                            }
                        });
                    });
                } else {
                    var mailData = 'email=' + $scope.userform.username + '&password=' + $scope.userform.password + '&action=login';
                    $http({
                        method: 'POST',
                        url: loginWithEmail,
                        data: mailData,
                        responseType: 'json',
                        params: { return_url: returnUrl },
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }).then(function successCallback(response) {
                        window.location.href = response.data.redirect_url;
                    }, function errorCallback(response) {
                        $scope['error' + response.status] = true;
                    });
                }
            } else {
                $scope.errorUserReq = (form.username.$error.required) ? true : false;
                $scope.errorUserPatt = (form.username.$error.pattern) ? true : false;
                if(!!form.password){
                    $scope.errorPassReq = (form.password.$error.required) ? true : false;
                    $scope.errorPassPatt = (form.password.$error.pattern) ? true : false;
                }
                if (form.terms && form.terms.$error){
                    $scope.errorCheckbox = (form.terms.$error.required) ? true : false;
                }
            }
        };

        // first API call
        Api.smartsection({
            SUBSECTION: 'smartlogin',
            CONTENT_ID: 0
        }).$promise.then(function(response){
            $scope.text = $sce.trustAsHtml(response.content);
        });
    }
);

'use strict';

angular.module('lite').controller('forgot',
    function($scope, $q, $sce, $http, Api, BarneyConfig, BarneyBrowser){

        var resetEmailPasswd = BarneyConfig.get('DEST_DOMAIN') + BarneyConfig.get('URLMGR_MIP-RESET-LINK');
        var VALIDATION_TEL = new RegExp(BarneyConfig.get('COUNTRY_REGEXP_VALIDATE_PLATINUM_MSISDN')),
            VALIDATION_EMAIL = new RegExp(BarneyConfig.get('REGEXP_VALIDATE_EMAIL')),
            INVALID = new RegExp(/(?=a)b/),
            userType = 'not defined';

        var aliasDefer = $q.defer();


        $scope.text = '';
        $scope.userform = {};

        if (!BarneyConfig.get('ENABLE_ALIAS_CHECK')) {

            // get phone number from query string, if present
            var msisdnQS = BarneyBrowser.getQueryParams().msisdn;
            if(typeof (msisdnQS) !== 'undefined' && msisdnQS !== 'true'){
                if(msisdnQS.indexOf(' ') === 0){
                    $scope.userform.username = msisdnQS.replace(' ', '+');
                    $scope.userform.phone_number = msisdnQS.replace(' ', '+');
                } else {
                    $scope.userform.username = msisdnQS;
                    $scope.userform.phone_number = msisdnQS;
                }
            }
        }

        /*******************
        ***   METHODS    ***
        *******************/

        //on input change - validates the username type and changes passwd validation pattern accordingly
        $scope.watchType = function(){
            $scope.VALIDATION_USERNAME = (function() {
                if(VALIDATION_EMAIL.test(document.querySelector('#username').value) === true) {
                    userType = 'email';
                    return VALIDATION_EMAIL;
                } else {
                    userType = 'msisdn';
                    return VALIDATION_TEL;
                }
            })();
        };

        $scope.submitForm = function(form) {
            if (form.$valid) {
                $scope.userform.phone_number = $scope.userform.username;
                if (userType === 'email') {
                    var mailData = 'email=' + $scope.userform.phone_number + '&action=reset';
                    $http.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
                    $http({
                        method: 'POST',
                        url: resetEmailPasswd,
                        data: mailData,
                        responseType: 'json'
                    }).then(function successCallback(response) {
                        location.href = '/mip/resetcheckemail';
                    }, function errorCallback(response) {
                        //$scope['error'+response.status] = true;
                        console.log('ko', response);
                    });
                } else {
                    if (BarneyConfig.get('ENABLE_ALIAS_CHECK')){
                        var phoneNumber = '';
                        if ($scope.userform.username.indexOf('+') >= 0) {
                            phoneNumber = $scope.userform.username.replace('+', '');
                        } else {
                            phoneNumber = $scope.userform.username;
                        }

                        Api.getAliasForMsisdn({ MSISDN: phoneNumber }).$promise.then(function (resp) {
                            aliasDefer.resolve(resp.alias);
                        });
                    } else {
                        aliasDefer.resolve(false);
                    }

                    aliasDefer.promise.then(function (resp) {
                        if (resp) {
                            $scope.userform.phone_number = resp;
                        } else {
                            $scope.userform.phone_number = $scope.userform.username;
                        }

                        document.getElementById('phone_number').value = $scope.userform.phone_number;

                        if (JsStorage.isLocalStorageSupported()) {
                            JsStorage.set('userform.username', $scope.userform.phone_number, { type: 'localstorage' });
                        }
                        document.getElementById('frmlogin').setAttribute('action', BarneyConfig.get('URLMGR_LOSTPASSWORD_URL'));
                        document.getElementById('frmlogin').submit();
                    });
                }
            }
        };

        Api.smartsection({
            SUBSECTION: 'smartforgot',
            CONTENT_ID: ''
        }, function(resp){
            $scope.text = $sce.trustAsHtml(resp.content);
            $scope.showFooter = true;
        });
    }
);



angular.module('lite').controller('canceled',
function($scope, BarneyConfig, $filter){
    $scope.hideButtonBackhome = BarneyConfig.get('HIDE_BUTTON_BACKHOME');
    $scope.redirect = '/#!/';
    if (DICTIONARY.WEBAPP_REDIRECT_URL_AFTER_UNSUB){
        $scope.redirect = DICTIONARY.WEBAPP_REDIRECT_URL_AFTER_UNSUB;
    }

    if (BarneyConfig.get('SNOWPLOW_TRACKING_ANALYTICS')) {
        if (window.snowplow !== undefined){
            window.snowplow('trackPageView', {
                context: [{
                    schema: 'iglu:com.dgp/page_category/jsonschema/1-0-0',
                    data: {
                        kind_of_page: 'cancelationpage'
                    }
                }]
            });
        }
    }
});
// FROM form_validation.tmpl - /dadanet2/webstore2/dati/tmpl/apps/inc/form_validation.tmpl

var pinPattern = CONFIG.COUNTRY_REGEXP_VALIDATE_PIN ? CONFIG.COUNTRY_REGEXP_VALIDATE_PIN : null;
var msisdnPattern = CONFIG.COUNTRY_REGEXP_VALIDATE_MSISDN ? CONFIG.COUNTRY_REGEXP_VALIDATE_MSISDN : null;
var msisdnerrormsg = DICTIONARY.WEBAPP_LOGIN_INVALID_NUMBER;
var pinerrormsg = DICTIONARY.WEBAPP_WRONG_PIN_FORMAT;
var checkPinBlank = CONFIG.CHECK_PIN_BLANK ? CONFIG.CHECK_PIN_BLANK : null;
var enableValidate = CONFIG.FIELD_VALIDATION ? true : false;
var validateRegexp = CONFIG.FIELD_VALIDATION_JS_REGEXP ? CONFIG.FIELD_VALIDATION_JS_REGEXP : null;

var googleChecked = function(){
    var termsObj = document.getElementById('terms');
    
    if (typeof termsObj !== 'undefined' && termsObj){
        if(termsObj.checked){
            document.getElementById('empty_terms').style.display = 'none';
        } else {    
            document.getElementById('empty_terms').style.display = 'block';
            return false;
        }
    }
};

if(CONFIG.SHOW_GOOGLE_CHECKBOX){
    googleChecked();
}


// FROM validate_key.js - /dadanet2/wl2/webstore_apps/js/webstore_apps/validate_key.js

function validate(evt) {
    if(!enableValidate) { return; }
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = validateRegexp;
    if(regex === null) { 
        regex = /[\d\x08]/; 
    }
    if(!regex.test(key)) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) { theEvent.preventDefault(); }
    }
}


angular.module('lite').controller('Login30Ctrl', [
    '$scope', '$rootScope', '$routeParams', '$window', 'BarneyConfig', 'BarneyDict', 'BarneyBrowser', 'newtonAdapterInterface', 'UserObj',
    function($scope, $rootScope, $routeParams, $window, Config, Dict, Browser, newtonAdapterInterface, UserObj){

        $scope.showLoggedKey = false;

        var redirectedRetUrl = JsStorage.get('redirect_after_login') || false;

        var returnUrl = redirectedRetUrl ? Config.get('REDIRECT_TO_PAGE_URL') : Browser.getQueryParams().return_url || '#!' + Config.get('ROUTER_HOME');

        if(UserObj.isLogged()){
            window.location.href = Config.get('CUSTOM_ROUTING.HOME.route');
        } else {

            useris.login({
                element: document.getElementById('blackboard'),
                access_token: newtonAdapterInterface.getUserToken()
            }, {
                onSuccess: function(userCredentials){

                    if (userCredentials.type === 'msisdn' && Config.get('INTERNATIONAL_PREFIX')) {
                        if (userCredentials.msisdn.indexOf(Config.get('INTERNATIONAL_PREFIX')) !== 0 && userCredentials.msisdn.match(Config.get('COUNTRY_REGEXP_VALIDATE_PLATINUM_MSISDN'))) {
                            userCredentials.msisdn = Config.get('INTERNATIONAL_PREFIX') + userCredentials.msisdn.match(Config.get('COUNTRY_REGEXP_VALIDATE_PLATINUM_MSISDN'))[1];
                            console.log('MSISDN optimized with ' + Config.get('INTERNATIONAL_PREFIX') + ' ', userCredentials.msisdn);
                        }
                    }
                    newtonAdapterInterface.login(userCredentials, returnUrl);
                },
                onError: function(error){
                    console.log(error);
                },
                onFacebookSuccess: function(){
                    console.log('To be implemented');
                },
                onFacebookError: function(){
                    console.log('To be implemented');
                }
            });

        }

        $scope.logout = function(){
            newtonAdapterInterface.logout();
        };

    }]);

'use strict';

angular.module('lite').controller('Forgot30Ctrl', [
    '$scope', '$routeParams', 'BarneyConfig', 'BarneyDict', 'newtonAdapterInterface', 'UserObj',
    function($scope, $routeParams, Config, Dict, newtonAdapterInterface, UserObj){
        $scope.showForgotKey = false;

        if(UserObj.isLogged()) {
            window.location.href = Config.get('CUSTOM_ROUTING.HOME.route');
        } else {
            useris.forgot({
                element: document.getElementById('blackboard'),
                access_token: newtonAdapterInterface.getUserToken(),
                smsTemplate: Dict.get('PASSWORD_RECOVERY_SMS') ? Dict.get('PASSWORD_RECOVERY_SMS') : ''
            }, {
                onSuccess: function(userCredentials){

                    if (userCredentials.msisdn && Config.get('INTERNATIONAL_PREFIX')) {
                        if (userCredentials.msisdn.indexOf(Config.get('INTERNATIONAL_PREFIX')) !== 0 && userCredentials.msisdn.match(Config.get('COUNTRY_REGEXP_VALIDATE_PLATINUM_MSISDN'))) {
                            userCredentials.msisdn = Config.get('INTERNATIONAL_PREFIX') + userCredentials.msisdn.match(Config.get('COUNTRY_REGEXP_VALIDATE_PLATINUM_MSISDN'))[1];
                            console.log('MSISDN optimized with ' + Config.get('INTERNATIONAL_PREFIX') + ' ', userCredentials.msisdn);
                        }
                    }
                    newtonAdapterInterface.forgot(userCredentials);
                },
                onError: function(error){
                    console.log(error);
                    window.location.reload();
                }
            });
        }

    }
]);

'use strict';

angular.module('lite').controller('Account30Ctrl', [
    '$rootScope', '$scope', '$sce', '$location', 'orderByFilter', 'Settings', 'UserObj', 'BarneyConfig', 'BarneyDict', '$window', 'BarneyBrowser', 'newtonAdapterInterface',
    function($rootScope, $scope, $sce, $location, orderByFilter, Settings, UserObj, Config, Dict, $window, Browser, newtonAdapterInterface){

        //Redirect fatto per Busuu: L'utente che si vuol cancellare arriva qui con un parametro definito in conf, che ci portiamo dietro alla login (deve funzionare su login normale, otp e login dentro accordion), per usarlo come returnurl dopo la login.
        if (Config.get('REDIRECT_TO_PAGE_AFTER_LOGIN') && Config.get('REDIRECT_TO_PAGE_URL') && Config.get('REDIRECT_TO_PAGE_PARAM')) {
            if (!JsStorage.get('redirect_after_login')){
                JsStorage.set('redirect_after_login', Browser.getQueryParams()[Config.get('REDIRECT_TO_PAGE_PARAM')] || '', { type: 'cookie', path: '/' });
            }
        }

        //  init variables
        $scope.showFooter = false;
        $scope.active = -1;
        $scope.showRestore = false;
        $scope.showLogoutButton = false;
        var userType = 'not defined';

        var redirectedRetUrl = JsStorage.get('redirect_after_login') || false;

        var returnUrl = redirectedRetUrl ? Config.get('REDIRECT_TO_PAGE_URL') : Browser.getQueryParams().return_url || '#!' + Config.get('ROUTER_HOME');

        $scope.isDadanetUser = false;
        $scope.hybridProductInfo = {};

        var settingsParams = {
            SUBSECTION: 'settings',
            CONTENT_ID: 0,
            HYBRID: 0,
            STORETYPE: 0
        };

        // Track Page view of snowplow
        if (window.snowplow !== undefined && Config.get('SNOWPLOW_TRACKING_ANALYTICS')) {
            window.snowplow('trackPageView', {
                context: [{
                    schema: 'iglu:com.dgp/page_category/jsonschema/1-0-0',
                    data: {
                        kind_of_page: 'myaccount'
                    }
                }]
            });
        }

        // Da sostituire nel codice
        var addSectionsToScope = function(content) {
            $scope.sections = orderByFilter(content, 'position');
            for(var k = 0; k < $scope.sections.length; k++){
                if($scope.sections[k].open_at_start){
                    $scope.open($scope.sections[k], k);
                    break;
                }
            }

            $scope.showLogoutButton = newtonAdapterInterface.isUserLogged() && !Config.get('HIDE_LOGOUT_BUTTON') && Config.get('ENABLE_NEWTON_USER');
            $scope.showFooter = true;
        };

        var unsubscriptionFlow = function(params){
            var redirectUrl = false;

            if (Config.get('HIDE_UNSUB_BUTTON')) {
                var btnContainer = document.querySelector('#box_buttons_status_confirm');
                if (btnContainer) {
                    var btns = btnContainer.children;
                    for (var i = 0; i < btns.length; i++) {
                        btns[i].disabled = true;
                        btns[i].style.pointerEvents = 'none';
                        btns[i].style.visibility = 'hidden';
                    }
                    var loader = document.createElement('div');
                    loader.className = 'loader general-margin-b-60';
                    btnContainer.parentElement.appendChild(loader);
                }
            }

            //Call MOA_API_USER_UNSUBSCRIBE;
            UserObj.unsubscribe(params, function(unsubResp){
                if(unsubResp){
                    console.log('Unsubscription completed', unsubResp);

                    useris.PopupManager.showUserFeedback({
                        feedback: {
                            message: Dict.get('WEBAPP_UO30_FEEDBACK_SUBS_DELETED')
                        },
                        type: 'positive'
                    });

                    if(unsubResp.config_key){
                        redirectUrl = Config.get(unsubResp.config_key);
                    } else if(unsubResp.url){
                        redirectUrl = unsubResp.url;
                    }

                    switch(unsubResp.status){

                    case 302:
                        if(unsubResp.unsubscribed){
                            NewtonAdapter.logout().then(function(logoutResp){
                                window.location.href = redirectUrl;
                            });
                        } else {
                            window.location.href = redirectUrl;
                        }

                        break;

                    case 200:
                        $scope.dictKey = Dict.get(unsubResp.dict_key);
                        break;

                    default:
                        $scope.dictKey = Dict.get(unsubResp.dict_key);
                    }
                } else {
                    $location.path(Config.get('ROUTER_ERROR'));
                }
            });
        };

        // open method
        $scope.open = function(section, index){
            // active/deactive section
            if($scope.active !== index){
                $scope.active = index;
            } else {
                $scope.active = -1;
            }

            // fill text
            if(!$scope.sections[index].text){ //$scope.sections[index].page_type !== $scope.LOCAL_PAGE_TYPE &&
                settingsParams.SUBSECTION = section.subsection;
                settingsParams.CONTENT_ID = section.content_id;

                Settings.get(settingsParams).$promise.then(function(resp){
                    $scope.sections[index].text = $sce.trustAsHtml(resp.content);

                    if(section.page_type === 'login'){
                        if(Config.get('ENABLE_NEWTON_USER')){
                            useris.login({
                                element: false,
                                access_token: newtonAdapterInterface.getUserToken(),
                                doNotLoad: true
                            }, {
                                onSuccess: function(userCredentials){

                                    if (userCredentials.type === 'msisdn' && Config.get('INTERNATIONAL_PREFIX')) {
                                        if (userCredentials.msisdn.indexOf(Config.get('INTERNATIONAL_PREFIX')) !== 0 && userCredentials.msisdn.match(Config.get('COUNTRY_REGEXP_VALIDATE_PLATINUM_MSISDN'))) {
                                            userCredentials.msisdn = Config.get('INTERNATIONAL_PREFIX') + userCredentials.msisdn.match(Config.get('COUNTRY_REGEXP_VALIDATE_PLATINUM_MSISDN'))[1];
                                            console.log('MSISDN optimized with ' + Config.get('INTERNATIONAL_PREFIX') + ' ', userCredentials.msisdn);
                                        }
                                    }
                                    newtonAdapterInterface.login(userCredentials);
                                },
                                onError: function(error){
                                    console.log(error);
                                },
                                onFacebookSuccess: function(userCredentials){
                                    console.log('To be implemented');
                                },
                                onFacebookError: function(err){
                                    console.log('To be implemented');
                                }
                            });
                        }
                    } else if(section.page_type === 'newtonsmartstatusprofile'){
                        useris.profile({
                            element: false,
                            access_token: newtonAdapterInterface.getUserToken(),
                            doNotLoad: true
                        }, {
                            onSaveNickname: function (obj) {
                                console.log('Username changed in: ' + obj.newNickname);

                                UserObj.setProperties({
                                    name: obj.newNickname
                                }, function (changedNickname) {
                                    console.log(changedNickname);

                                    if (changedNickname) {
                                        obj.callbackSuccess(obj.newNickname);

                                        useris.PopupManager.showUserFeedback({
                                            feedback: {
                                                message: Dict.get('WEBAPP_UO30_FEEDBACK_NICKNAME_UPDATED')
                                            },
                                            type: 'positive'
                                        });
                                    } else {
                                        useris.PopupManager.showUserFeedback({
                                            feedback: {
                                                message: Dict.get('WEBAPP_UO30_ERROR_NEWTON_GENERIC')
                                            },
                                            type: 'error'
                                        });
                                    }
                                });
                            },
                            onError: function (error) {
                                useris.triggerError('useris-username-error');
                            }
                        });
                    } else if (section.page_type === 'newtonsmartstatussocial') {
                        useris.social({
                            element: false,
                            access_token: newtonAdapterInterface.getUserToken(),
                            doNotLoad: true
                        }, {
                            onFacebookRegisterSuccess: function(){
                                console.log('Facebook login');
                            },
                            onFacebookDisconnect: function(){
                                console.log('Facebook disconnect');
                            }
                        });
                    } else if(section.page_type === 'smartstatus'){
                        if(Config.get('ENABLE_NEWTON_USER')){
                            useris.smartstatus({
                                element: false,
                                access_token: newtonAdapterInterface.getUserToken(),
                                doNotLoad: true
                            }, {
                                onUnsubUnloggedSuccess: function(params){
                                    if (params.TYPE === 'msisdn' && Config.get('INTERNATIONAL_PREFIX')) {
                                        if (params.IDENTITY.indexOf(Config.get('INTERNATIONAL_PREFIX')) !== 0 && params.IDENTITY.match(Config.get('COUNTRY_REGEXP_VALIDATE_PLATINUM_MSISDN'))) {
                                            params.IDENTITY = Config.get('INTERNATIONAL_PREFIX') + params.IDENTITY.match(Config.get('COUNTRY_REGEXP_VALIDATE_PLATINUM_MSISDN'))[1];
                                            console.log('MSISDN optimized with ' + Config.get('INTERNATIONAL_PREFIX') + ' ', params.IDENTITY);
                                        }
                                    }

                                    unsubscriptionFlow(params);
                                },
                                onUnsubUnloggedError: function(err){
                                    console.log(err);
                                },
                                onUnsubLoggedSuccess: function(params){
                                    unsubscriptionFlow(params);
                                },
                                onUnsubLoggedError: function(err){
                                    console.log(err);
                                }
                            });
                        } else {

                            var VALIDATION_PIN = new RegExp(Config.get('COUNTRY_REGEXP_VALIDATE_PLATINUM_PIN')),
                                VALIDATION_TEL = new RegExp(Config.get('COUNTRY_REGEXP_VALIDATE_PLATINUM_MSISDN'));

                            // TO BE FIND ANOTHER SOLUTION
                            console.log('User 2.0 status: action login required!');

                            var validationPinRegexp = new RegExp(VALIDATION_PIN),
                                validationMsisdnRegexp = new RegExp(VALIDATION_TEL);

                            $scope.userform = { username: '', password: '' };

                            $scope.errorUserReq = false;
                            $scope.errorUserPatt = false;
                            $scope.errorPassReq = false;
                            $scope.errorPassPatt = false;
                            $scope.errorCheckbox = false;
                            $scope.error401 = false;
                            $scope.error403 = false;

                            // on input change - validates the username type and changes passwd validation pattern accordingly
                            $scope.watchType = function(){
                                $scope.VALIDATION_USERNAME = (function() {
                                    if(validationMsisdnRegexp.test(document.querySelector('#username').value) === true){
                                        userType = 'msisdn';
                                        $scope.VALIDATION_PASSWORD = validationPinRegexp;
                                        return VALIDATION_TEL;
                                    }
                                })();
                            };

                            $scope.submitForm = function(form) {
                                $scope.errorUserReq = false;
                                $scope.errorUserPatt = false;
                                $scope.errorPassReq = false;
                                $scope.errorPassPatt = false;
                                $scope.errorCheckbox = false;
                                $scope.error401 = false;
                                $scope.error403 = false;

                                if (form.$valid) {
                                    Settings.get({
                                        SUBSECTION: 'smartlogin',
                                        CONTENT_ID: '',
                                        username: $scope.userform.username,
                                        password: $scope.userform.password,
                                        return_url: returnUrl
                                    }, function(e){
                                        switch(e.status){
                                        case '302':
                                            window.location.href = e.content;
                                            break;
                                        default:
                                            $scope.text = $sce.trustAsHtml(e.content);
                                            break;
                                        }
                                    });
                                } else {
                                    $scope.errorUserReq = (form.username.$error.required) ? true : false;
                                    $scope.errorUserPatt = (form.username.$error.pattern) ? true : false;
                                    if(!!form.password){
                                        $scope.errorPassReq = (form.password.$error.required) ? true : false;
                                        $scope.errorPassPatt = (form.password.$error.pattern) ? true : false;
                                    }
                                    if (form.terms && form.terms.$error){
                                        $scope.errorCheckbox = (form.terms.$error.required) ? true : false;
                                    }
                                }
                            };
                        }
                    }
                });
            }
        };


        $scope.userLogout = function(){
            if (Config.get('ENABLE_NEWTON_USER')){
                newtonAdapterInterface.logout();
            }
        };

        Settings.getList(settingsParams).$promise.then(function(resp){
            addSectionsToScope(resp.content);
        });


    }
]);



angular.module('lite').factory('newtonAdapterInterface',
    ['BarneyConfig', 'loginService', 'forgotService', 'logoutService', 'userService', 'getIdentityService',
        function (Config, loginService, forgotService, logoutService, userService, getIdentityService) {

            return{
                login: function(userCredentials, returnUrl){
                    loginService.sendLoginCredentials(userCredentials, returnUrl);
                },
                logout: function(){
                    logoutService.logout();
                },
                forgot: function(userCredentials){
                    forgotService.recoverCredentials(userCredentials);
                },
                isUserLogged: function(){
                    return userService.isUserLogged();
                },
                getUserToken: function(){
                    return userService.getUserToken();
                },
                getIdentity: function(identityType){
                    getIdentityService.getIdentity(identityType);
                } 

            };

        }]);



'use strict';

angular.module('lite').factory('loginService',
    ['$resource', 'BarneyConfig', 'BarneyDict', '$window', 'EventBus',
        function ($resource, Config, Dict, $window, EventBus) {

            var loginService = {
                sendLoginCredentials: sendLoginCredentials
            };

            function sendLoginCredentials(userCredentials, returnUrl){
                // call NewtonAdapter login
                NewtonAdapter.login(userCredentials).then(function(resp){

                    // when login is successful, redirect to returnUrl
                    EventBus.on('User-Login', function(){

                        if (Config.get('UO30_FORCE_REFRESH_AFTER_LOGIN')) {

                            JsStorage.set('user-login-show-feedback', 'true', { type: 'cookie', path: '/' });

                            $window.location.href = '/';

                        } else {
                            useris.PopupManager.showUserFeedback({
                                feedback: {
                                    message: Dict.get('WEBAPP_UO30_FEEDBACK_LOGIN')
                                },
                                type: 'positive'
                            });

                            $window.location.href = returnUrl;
                        }
                    });

                }).catch(function(err){
                    var dictKey = Dict.get('WEBAPP_UO30_ERROR_NEWTON_' + err.code.toUpperCase()) ?
                    Dict.get('WEBAPP_UO30_ERROR_NEWTON_' + err.code.toUpperCase()) : Dict.get('WEBAPP_UO30_ERROR_NEWTON_GENERIC');

                    if(dictKey){
                        useris.PopupManager.showUserFeedback({
                            type: 'error',
                            feedback: {
                                message: dictKey
                            }
                        });
                    }
                });

            }

            return loginService;

        }]);



angular.module('lite').factory('logoutService',
    ['$resource', 'BarneyConfig', 'BarneyDict',
        function ($resource, Config, Dict) {

            var logoutService = {
                logout: logout
            };

            function logout(){

                NewtonAdapter.logout().then(function (resp) {
                    if (Config.get('UO30_FORCE_REFRESH_AFTER_LOGIN')) {

                        if (Config.get('ENABLE_NEWTON_USER')) {
                            JsStorage.set('user-logout-show-feedback', 'true', { type: 'cookie', path: '/' });
                        }

                        window.location.href = '/';

                    } else {
                        if (Config.get('ENABLE_NEWTON_USER')) {
                            useris.PopupManager.showUserFeedback({
                                feedback: {
                                    message: Dict.get('WEBAPP_UO30_FEEDBACK_LOGOUT')
                                },
                                type: 'positive'
                            });
                        }

                        window.location.href = '#!' + Config.get('ROUTER_HOME');
                    }

                }).catch(function (err) {
                    console.log('err', err);
                    window.location.href = '#!' + Config.get('ROUTER_ERROR');
                });
            }


            return logoutService;

        }]);


'use strict';

angular.module('lite').factory('forgotService',
    ['$resource', 'BarneyConfig', 'BarneyDict',
        function ($resource, Config, Dict) {

            var forgotService = {
                recoverCredentials: recoverCredentials
            };

            function recoverCredentials(userCredentials){
                NewtonAdapter.recoverPassword(userCredentials).then(function(resp){
                    console.log(resp);
                    if(userCredentials.msisdn){

                        useris.PopupManager.showUserFeedback({
                            feedback: {
                                message: Dict.get('WEBAPP_UO30_SUGGESTION_FORGOT_CHECKSMS'),
                                icon: 'icon-sms'
                            },
                            type: 'generic'
                        });

                        window.location.href = '#!' + Config.get('ROUTER_LOGIN') + '?sentpin=1&msisdn=' + encodeURIComponent(userCredentials.msisdn);
                    } else if(userCredentials.email) {

                        useris.PopupManager.showUserFeedback({
                            feedback: {
                                message: Dict.get('WEBAPP_UO30_SUGGESTION_FORGOT_CHECKMAIL'),
                                icon: 'icon-email'
                            },
                            type: 'generic'
                        });

                        window.location.href = '#!' + Config.get('ROUTER_HOME');

                        $scope.$apply();
                    }
                }).catch(function(error){
                    if(userCredentials.msisdn){
                        useris.PopupManager.showUserFeedback({
                            feedback: {
                                message: Dict.get('WEBAPP_UO30_SUGGESTION_FORGOT_CHECKSMS'),
                                icon: 'icon-sms'
                            },
                            type: 'generic'
                        });

                        window.location.href = '#!' + Config.get('ROUTER_LOGIN') + '?sentpin=1&msisdn=' + encodeURIComponent(userCredentials.msisdn);
                    } else if(userCredentials.email) {

                        useris.PopupManager.showUserFeedback({
                            feedback: {
                                message: Dict.get('WEBAPP_UO30_SUGGESTION_FORGOT_CHECKMAIL'),
                                icon: 'icon-email'
                            },
                            type: 'generic'
                        });

                        window.location.href = '#!' + Config.get('ROUTER_HOME');
                    }
                });

            }


            return forgotService;

        }]);

'use strict';

angular.module('lite').factory('userService',
    ['$resource', 'BarneyConfig', 
        function ($resource, Config) {
            
            var userToken = '';
            var userService = {
                isUserLogged: isUserLogged,
                getUserToken: getUserToken
            };

            function isUserLogged(){
                
                return NewtonAdapter.isUserLogged();
                
            }
            
            function getUserToken(){
                if(this.isUserLogged()){
                    userToken = NewtonAdapter.getUserToken();
                }
                return userToken;
            }

        
            return userService;
        
        }]);

angular.module('lite').factory('getIdentityService',
    ['$resource', 'BarneyConfig', 'BarneyDict', 'Mail', '$routeParams',
        function ($resource, Config, Dict, Mail, $routeParams) {

            var getIdentityService = {
                getIdentity: getIdentity
            };

            function getIdentity(identityType){

                NewtonAdapter.getIdentity(identityType).then(function(genericIdentity){

                    useris.PopupManager.showUserFeedback({
                        type: 'positive',
                        feedback: {
                            message: Dict.get('WEBAPP_UO30_FEEDBACK_EMAILADDED_CONFIRMED')
                        }
                    });


                    if(genericIdentity){
                        genericIdentity.delete(function(err){
                            if(err){
                                console.log(err);
                                window.location.href = '#!' + Config.get('ROUTER_ERROR');
                            } else {
                                console.log('Generic identity Removed!');
                            }
                        });
                    }

                    Mail.sendWelcomeMail({
                        EMAIL: $routeParams.email,
                        USERNAME: $routeParams.email
                    }).$promise.then(function(mailResp){
                        console.log(mailResp);

                        window.location.href = '#!' + Config.get('ROUTER_HOME');
                    });

                }).catch(function(err){
                    console.log(err);
                });
            }

            return getIdentityService;

        }]);

'use strict';

angular.module('lite').factory('resetPasswordService',
    ['$resource', 'BarneyConfig', 'BarneyDict', 'Mail',
        function ($resource, Config, Dict, Mail) {

            var resetPasswordService = {
                resetPassword: resetPassword
            };

            function resetPassword(userCredentials){
                NewtonAdapter.resetPassword(userCredentials).then(function (resp) {
                    if (Config.get('SEND_EMAIL_AFTER_RESET')) {
                        Mail.sendAfterReset({
                            USERNAME: $routeParams.email,
                            EMAIL: $routeParams.email
                        }).$promise.then(function (mailResp) {

                            useris.PopupManager.showUserFeedback({
                                feedback: {
                                    message: Dict.get('WEBAPP_UO30_FEEDBACK_PSWRESET')
                                },
                                type: 'positive'
                            });

                            window.location.href = '#!' + Config.get('ROUTER_LOGIN');
                        });
                    } else {

                        useris.PopupManager.showUserFeedback({
                            feedback: {
                                message: Dict.get('WEBAPP_UO30_FEEDBACK_PSWRESET')
                            },
                            type: 'positive'
                        });

                        window.location.href = '#!' + Config.get('ROUTER_LOGIN');
                    }
                }).catch(function (err) {
                    var dictKey = Dict.get('WEBAPP_UO30_ERROR_NEWTON_' + err.code.toUpperCase()) ?
                        Dict.get('WEBAPP_UO30_ERROR_NEWTON_' + err.code.toUpperCase()) : Dict.get('WEBAPP_UO30_ERROR_NEWTON_GENERIC');

                    if (dictKey) {
                        useris.PopupManager.showUserFeedback({
                            type: 'error',
                            feedback: {
                                message: dictKey
                            }
                        });
                    }
                });

            }

            return resetPasswordService;

        }]);


/*

*/

'use strict';

angular.module('lite').factory('emailService',
    ['$resource', 'BarneyConfig', 'BarneyDict', '$routeParams',
        function ($resource, Config, Dict, $routeParams) {

            var emailService = {
                confirmEmail: confirmEmail,
                confirmEmailAndLogin: confirmEmailAndLogin
            };

            function confirmEmail(newtonConfirmCallback){

                NewtonAdapter.confirmEmail({
                    token: $routeParams.token
                }).then(function(resp){
                    newtonConfirmCallback();
                }).catch(function(err){
                    var dictKey = Dict.get('WEBAPP_UO30_ERROR_NEWTON_' + err.code.toUpperCase()) ?
                        Dict.get('WEBAPP_UO30_ERROR_NEWTON_' + err.code.toUpperCase()) : Dict.get('WEBAPP_UO30_ERROR_NEWTON_GENERIC');

                    if (dictKey) {
                        useris.PopupManager.showUserFeedback({
                            type: 'error',
                            feedback: {
                                message: dictKey
                            }
                        });
                    }

                    window.location.href = '#!' + Config.get('ROUTER_ERROR') + '?code=' + err.code;
                });
            }

            function confirmEmailAndLogin(newtonConfirmCallback){

                NewtonAdapter.confirmEmailAndLogin({
                    token: $routeParams.token
                }).then(function(resp){
                    newtonConfirmCallback();
                }).catch(function(err){
                    console.log('confirmEmail failed', err);

                    var dictKey = Dict.get('WEBAPP_UO30_ERROR_NEWTON_' + err.code.toUpperCase()) ?
                    Dict.get('WEBAPP_UO30_ERROR_NEWTON_' + err.code.toUpperCase()) : Dict.get('WEBAPP_UO30_ERROR_NEWTON_GENERIC');

                    useris.PopupManager.showUserFeedback({
                        type: 'error',
                        feedback: {
                            message: dictKey
                        }
                    });

                    window.location.href = '#!' + Config.get('ROUTER_ERROR') + '?code=' + err.code;
                });
            }


            return emailService;

        }]);

angular.module('lite').factory('Mail',
    ['$resource', 'BarneyConfig', 'generateWelcomeMailUrlFilter',
        function ($resource, Config, generateWelcomeMailUrl) {

            return $resource('', {}, {

                sendAfterChangePassword: {
                    method: 'GET',
                    url: Config.get('MOA_API_MOA_MAILER_PASSWORDCHANGED'),
                    isArray: false,
                    cache: false,
                    transformResponse: function(data){
                        var object = angular.fromJson(data);
                        JsLogger.info('API', 'Zoom', 'get', object);
                        return object;
                    }
                },

                sendAfterReset: {
                    method: 'GET',
                    url: Config.get('MOA_API_MOA_MAILER_RESETPASSWORD'),
                    isArray: false,
                    cache: false,
                    transformResponse: function(data){
                        var array = angular.fromJson(data);
                        JsLogger.info('API', 'Zoom', 'getRelated', array);
                        return array;
                    }
                },

                sendWelcomeMail: {
                    method: 'GET',
                    url: generateWelcomeMailUrl(Config.get('MOA_API_MOA_MAILER_WELCOMEMAIL'), 'MAILPRODUCT_OTHERPARAMS'),
                    isArray: false,
                    cache: false,
                    transformResponse: function(data){
                        var array = angular.fromJson(data);
                        JsLogger.info('API', 'Mail', 'sendWelcomeMail', array);
                        return array;
                    }
                }

            });
        }
    ]
  );



angular.module('lite').factory('UserObj', 
    function (BarneyConfig, User20, User30) {  
        if(BarneyConfig.get('ENABLE_NEWTON_USER')){
            return User30;
        } else {
            return User20;
        }
    }
);
'use strict';

angular.module('lite').factory('User20',
function($rootScope, $q, BarneyConfig, EventBus){

    var userInitPromise = $q.defer();
    $rootScope.userReady = false;

    var apiCalls = {
        isLogged: function(){
            return BarneyConfig.get('USER_DATA.IS_LOGGED');
        },

        getProperty: function(property){
            return BarneyConfig.get('USER_DATA.' + property);
        }
    };


    return {
        init: function(callback){
            userInitPromise.resolve({ initialized: true });
            if(CONFIG.ENABLE_USERCHECK && CONFIG.MOA_API_USER_CHECK && CONFIG.USER_DATA.IS_LOGGED){

                var userCheckUrl = CONFIG.MOA_API_USER_CHECK;

                if(userCheckUrl.indexOf(':CONTENT_ID') > 0) {
                    userCheckUrl = userCheckUrl.replace(':CONTENT_ID', '0');
                }

                fetch(userCheckUrl, {
                    credentials: 'same-origin'
                }).then(function(response) {

                    return response.json();
                }, function(error){
                    console.error(error);
                }).then(function(userData){
                    if(callback){ callback({ initialized: true }); }
                    EventBus.trigger('User-Init', { status: 'user20' });
                    window.userData = userData;
                });
            }else{
                if(callback){ callback({ initialized: true }); }
                EventBus.trigger('User-Init', { status: 'user20' });
               // window.userData = userData;
            }
        },
        isLogged: function(){
            return apiCalls.isLogged();
        },
        getProperty: function(property, callback){
            EventBus.on('User-init', function(){
                if(callback){ callback(apiCalls.getProperty(property)); }
            });
        },
        unsubscribe: function(callback){
            EventBus.on('User-init', function(){
                if(callback){ callback({ status: 'user20' }); }
            });
        },
        getStatus: function(callback){
            EventBus.on('User-init', function(){
                if(callback){ callback({ status: 'user20' }); }
            });
        },
        setProperties: function(properties, callback){
            EventBus.on('User-init', function(){
                if(callback){ callback(properties); }
            });
        }
    };
});

angular.module('lite').factory('User30', [
    '$resource',
    '$rootScope',
    '$q',
    'BarneyConfig',
    'EventBus',
    'DateUtility',
    function ($resource, $rootScope, $q, Config, EventBus, DateUtility) {
        /**************************
         ***    PRIVATE SPACE    ***
         **************************/

        $rootScope.userReady = false;

        var apiCalls = $resource(
            '',
            {},
            {
                userCheck: {
                    method: 'GET',
                    url: Config.get('MOA_API_USER_PROFILE_GET'),
                    isArray: false,
                    cache: false,
                    withCredentials: true,
                    params: {
                        ACCESS_TOKEN: function () {
                            return NewtonAdapter.isUserLogged()
                                ? NewtonAdapter.getUserToken()
                                : '';
                        }
                    },
                    transformResponse: function (data) {
                        var object = angular.fromJson(data);
                        return object;
                    }
                },
                userUpdate: {
                    method: 'POST',
                    url: Config.get('MOA_API_MIP_USER_PROFILE_UPDATE'),
                    isArray: false,
                    cache: false,
                    withCredentials: true,
                    params: {
                        ACCESS_TOKEN: function () {
                            return NewtonAdapter.isUserLogged()
                                ? NewtonAdapter.getUserToken()
                                : '';
                        }
                    },
                    transformResponse: function (data) {
                        var object = angular.fromJson(data);
                        return object;
                    }
                },
                userUnsubscribe: {
                    method: 'GET',
                    url: Config.get('MOA_API_USER_UNSUBSCRIBE'),
                    isArray: false,
                    cache: false,
                    withCredentials: true,
                    params: {
                        ACCESS_TOKEN: function () {
                            return NewtonAdapter.isUserLogged()
                                ? NewtonAdapter.getUserToken()
                                : '';
                        }
                    },
                    transformResponse: function (data) {
                        var object = angular.fromJson(data);
                        return object;
                    }
                }
            }
        );

        /**************************
         ***    PUBLIC SPACE     ***
         **************************/

        return {
            init: function (callback) {
                var _this = this;

                if (NewtonAdapter.isUserLogged()) {
                    apiCalls.userCheck().$promise.then(function (resp) {
                        //TODO caso suspended
                        if (resp.suspended) {
                            console.log('suspended - init', resp);

                        }
                        $rootScope.userReady = true;
                        $rootScope.userLogged = true;
                        $rootScope.busuuLoginUrl = false;
                        $rootScope.loginUrlIsExpired = true;
                        $rootScope.userBilled = Config.get('USER_CHECK_BILLED_STATUS')
                            ? resp.billed
                            : true;
                        $rootScope.userSubscribed = Config.get('USER_CHECK_BILLED_STATUS')
                            ? resp.subscribed
                            : true;

                        if (
                            Config.get('AUTOLOGIN_EXPIRATION_DATE') &&
                            typeof resp.busuu_login_url_expire_ts !== 'undefined'
                        ) {
                            $rootScope.loginUrlIsExpired = DateUtility.timestampIsExpired(
                                resp.busuu_login_url_expire_ts
                            );
                            $rootScope.busuuLoginUrl = !$rootScope.loginUrlIsExpired
                                ? resp.busuu_login_url
                                : false;


                        }

                        if (callback) {
                            callback(resp);
                        }
                        JsLogger.info(
                            'API',
                            'User',
                            'init',
                            'logged',
                            NewtonAdapter.getUserToken(),
                            resp
                        );
                        EventBus.trigger('User-Init', resp);
                        /*
                                        IF USER_CHECK_BILLED_STATUS - da guecio
                                            logged=true, sub=true, billed=true ----> DLD Store
                                            logged=true, sub=true, billed=false ----> MESSAGGE no Credits
                                            logged=true, sub=false, billed=true ----> NON possibile, se lo vuoi gestire mandalo alla LP
                                            logged=true, sub=false, billed=false ---->LP (era la limit di playme)
                                            logged=false, sub=false, billed=true ----> NON possibile, se lo vuoi gestire mandalo alla LP
                                            logged=false, sub=false, billed=false ---->LP
                                        */
                    });
                } else {
                    var userCheckObject = {
                        logged: 0,
                        message: 'Not found',
                        status: '404',
                        subscribed: false
                    };
                    $rootScope.userReady = true;
                    $rootScope.userLogged = false;
                    if (callback) {
                        callback(userCheckObject);
                    }
                    JsLogger.info('API', 'User', 'init', 'unlogged', userCheckObject);
                    EventBus.trigger('User-Init', userCheckObject);
                }
            },

            isLogged: function () {
                return NewtonAdapter.isUserLogged();
            },

            getProperty: function (property, callback) {
                EventBus.on('User-Init', function () {
                    apiCalls.userCheck().$promise.then(function (resp) {
                        callback(resp[property]);
                    });
                });
            },
            unsubscribe: function (params, callback) {
                var unsubscribeUser = function () {
                    apiCalls.userUnsubscribe(
                        params,
                        function (resp) {
                            EventBus.off('User-Init', unsubscribeUser);
                            if (callback) {
                                callback(resp);
                            }
                        },
                        function (err) {
                            if (callback) {
                                callback(false);
                            }
                        }
                    );
                };

                EventBus.on('User-Init', unsubscribeUser);
            },

            getStatus: function (callback) {
                EventBus.on('User-Init', function (resp) {
                    if (callback) {
                        callback(resp);
                    }
                });
            },

            setProperties: function (properties, callback) {
                var newProperties = {
                    data_user: properties
                };
                EventBus.on('User-Init', function () {
                    apiCalls
                        .userUpdate(newProperties)
                        .$promise.then(function (userUpdateResp) {
                            if (callback) {
                                callback(userUpdateResp);
                            }
                        });
                });
            }
        };
    }
]);

angular.module('lite').factory('Settings',
    ['$resource', 'BarneyConfig',
        function ($resource, Config) {

            return $resource('', {}, {

                get: {
                    method: 'GET',
                    url: Config.get('MOA_API_SMART_SECTION'),
                    isArray: false,
                    cache: false,
                    params: {
                        access_token: function(){
                            return Config.get('ENABLE_NEWTON_USER') && NewtonAdapter.isUserLogged() ? NewtonAdapter.getUserToken() : '';
                        }
                    },
                    transformResponse: function(data){
                        var object = angular.fromJson(data);
                        JsLogger.info('API', 'Settings', 'get', object);
                        return object;
                    }
                },

                getList: {
                    method: 'GET',
                    url: Config.get('MOA_API_SMART_SECTION'),
                    isArray: false,
                    cache: false,
                    params: {
                        access_token: function(){
                            return Config.get('ENABLE_NEWTON_USER') && NewtonAdapter.isUserLogged() ? NewtonAdapter.getUserToken() : '';
                        }
                    },
                    transformResponse: function(data){
                        var object = angular.fromJson(data);
                        JsLogger.info('API', 'Settings', 'getList', object);
                        return object;
                    }
                },

                getAliasForMsisdn: {
                    method: 'GET',
                    url: Config.get('ALIAS_CHECK_URL'),
                    isArray: false,
                    cache: false,
                    withCredentials: false,
                    transformResponse: function(data){
                        var object = angular.fromJson(data);
                        JsLogger.info('API', 'User', 'getAliasForMsisdn', object);
                        return object;
                    }
                },

                getNetworkProperty: {
                    method: 'GET',
                    url: Config.get('DEST_DOMAIN') + Config.get('NETWORK_INFO_API_URL'),
                    isArray: false,
                    cache: false,
                    transformResponse: function(data){
                        var object = angular.fromJson(data);
                        JsLogger.info('API', 'Settings', 'getNetworkProperty', object);
                        return object;
                    }
                }
            });
        }
    ]
  );

'use strict';

angular.module('lite').controller('error', 
function($scope, $routeParams, $sce, BarneyBrowser, BarneyConfig, Api, $filter){

    // Get route params
    if($routeParams.code){
        $scope.code = $routeParams.code;
    } else if(BarneyBrowser.getQueryParams().code) {
        $scope.code = BarneyBrowser.getQueryParams().code.replace('/', '');
    } else if($routeParams.error) {
        $scope.code = $routeParams.error;
    } else if(BarneyBrowser.getQueryParams().error) {
        $scope.code = BarneyBrowser.getQueryParams().error.replace('/', '');
    } else {
        $scope.code = false;
    }

    // Get error template, if necessary
    $scope.showErrorTmpl = BarneyConfig.get('SHOW_TMPL_ERROR');
    if($scope.showErrorTmpl){
        Api.smartsection({
            SUBSECTION: 'error',
            error: $scope.code || ''
        }).$promise.then(function(response){
            $scope.errorTmpl = $sce.trustAsHtml(response.content);
        });
    }

    $scope.isOperatorReqEqualUser = false;
    $filter('userprop')('getProperty', 'operator', function(operator){ 
        $scope.isOperatorReqEqualUser = BarneyConfig.get('OPERATOR_REQUESTS') === operator;
    });

});
'use strict';

angular.module('lite').controller('terms',
function($scope, $route, $routeParams, $sce, $location, Api, BarneyMeta, BarneyDict, BarneyConfig, GoogleManager, newtonAdapterInterface, UserObj){

    var subsectionParam = $routeParams.subsection || '';
    var contentidParam = $routeParams.content_id || '';

    if($route.current.customParams){
        if($route.current.customParams.SUBSECTION){
            subsectionParam = $route.current.customParams.SUBSECTION;
        }
        if($route.current.customParams.CONTENT_ID){
            contentidParam = $route.current.customParams.CONTENT_ID;
        }
    }

    if(BarneyConfig.get('ENABLE_META_TAGS_ON_TERMS')){
        BarneyMeta.set({
            title: BarneyDict.get('SEO_WEBAPP_TITLE_COUNTRY') + (BarneyDict.get('SEO_WEBAPP_TITLE_' + contentidParam.toUpperCase()) ? BarneyDict.get('SEO_WEBAPP_TITLE_' + contentidParam.toUpperCase()) : '')
        }, function(){
            if(BarneyConfig.get('GOOGLE_TAG_ENABLE')){
                GoogleManager.set({
                    'Tipo de pagina': BarneyDict.get('WEBAPP_NAVIGATION_SERVICE_OTHER'),
                    'Tipo de pagina /h': BarneyDict.get('WEBAPP_NAVIGATION_SERVICE_OTHER_H'),
                    Contenido: BarneyDict.get('WEBAPP_NO_APPLICABLE_CONTENT'),
                    'Contenido /h': BarneyDict.get('WEBAPP_NO_APPLICABLE_CONTENT_H'),
                    event: 'changePage'
                });
            }
        });
    }

    // Track Page view of snowplow

    var checkLocation = $location.path();
    var typeOfPage = '';

    if (checkLocation.includes('status')){
        typeOfPage = 'cancelationpage';
    } else {
        typeOfPage = 'termsandconditions';
    }
    if (window.snowplow !== undefined && BarneyConfig.get('SNOWPLOW_TRACKING_ANALYTICS')){
        window.snowplow('trackPageView', {
            context: [{
                schema: 'iglu:com.dgp/page_category/jsonschema/1-0-0',
                data: {
                    kind_of_page: typeOfPage
                }
            }]
        });
    }

    var unsubscriptionFlow = function(params){
        var redirectUrl = false;

        if (BarneyConfig.get('HIDE_UNSUB_BUTTON')) {
            var btnContainer = document.querySelector('#box_buttons_status_confirm');
            if (btnContainer) {
                var btns = btnContainer.children;
                for (var i = 0; i < btns.length; i++) {
                    btns[i].disabled = true;
                    btns[i].style.pointerEvents = 'none';
                    btns[i].style.visibility = 'hidden';
                }
                var loader = document.createElement('div');
                loader.className = 'loader general-margin-b-60';
                btnContainer.parentElement.appendChild(loader);
            }
        }

        //Call MOA_API_USER_UNSUBSCRIBE;
        UserObj.unsubscribe(params, function(unsubResp){
            if(unsubResp){
                console.log('Unsubscription completed', unsubResp);

                useris.PopupManager.showUserFeedback({
                    feedback: {
                        message: BarneyDict.get('WEBAPP_UO30_FEEDBACK_SUBS_DELETED')
                    },
                    type: 'positive'
                });

                if(unsubResp.config_key){
                    redirectUrl = BarneyConfig.get(unsubResp.config_key);
                } else if(unsubResp.url){
                    redirectUrl = unsubResp.url;
                }

                switch(unsubResp.status){

                case 302:
                    if(unsubResp.unsubscribed){
                        NewtonAdapter.logout().then(function(logoutResp){
                            window.location.href = redirectUrl;
                        });
                    } else {
                        window.location.href = redirectUrl;
                    }

                    break;

                case 200:
                    $scope.dictKey = BarneyDict.get(unsubResp.dict_key);
                    break;

                default:
                    $scope.dictKey = BarneyDict.get(unsubResp.dict_key);
                }
            } else {
                $location.path(BarneyConfig.get('ROUTER_ERROR'));
            }
        });
    };

    Api.smartsection({
        SUBSECTION: subsectionParam,
        CONTENT_ID: contentidParam,
        access_token: NewtonAdapter.isUserLogged() ? NewtonAdapter.getUserToken() : ''
    }).$promise.then(function(response){
        $scope.text = $sce.trustAsHtml(response.content);

        if(BarneyConfig.get('ENABLE_NEWTON_USER') && subsectionParam === 'smartstatus'){
            useris.smartstatus({
                element: false,
                access_token: newtonAdapterInterface.getUserToken(),
                doNotLoad: true
            }, {
                onUnsubUnloggedSuccess: function(params){
                    if (params.TYPE === 'msisdn' && BarneyConfig.get('INTERNATIONAL_PREFIX')) {
                        if (params.IDENTITY.indexOf(BarneyConfig.get('INTERNATIONAL_PREFIX')) !== 0 && params.IDENTITY.match(BarneyConfig.get('COUNTRY_REGEXP_VALIDATE_PLATINUM_MSISDN'))) {
                            params.IDENTITY = BarneyConfig.get('INTERNATIONAL_PREFIX') + params.IDENTITY.match(BarneyConfig.get('COUNTRY_REGEXP_VALIDATE_PLATINUM_MSISDN'))[1];
                            console.log('MSISDN optimized with ' + BarneyConfig.get('INTERNATIONAL_PREFIX') + ' ', params.IDENTITY);
                        }
                    }

                    unsubscriptionFlow(params);
                },
                onUnsubUnloggedError: function(err){
                    console.log(err);
                },
                onUnsubLoggedSuccess: function(params){
                    unsubscriptionFlow(params);
                },
                onUnsubLoggedError: function(err){
                    console.log(err);
                }
            });
        }
    });
});

'use strict';

angular.module('lite').factory('Mfp',
    function(BarneyConfig, Api, $location){

        return {
            setAndRedirect: function(storeType){
                NewtonAdapter.trackEvent({
                    name: 'GoToStorePromoClick',
                    properties: {
                        action: 'NativeAppPromoClick',
                        category: 'Behavior',
                        label: 'gotostore',
                        page: window.location.href
                    }
                });

                if (window.snowplow !== undefined && BarneyConfig.get('SNOWPLOW_TRACKING_ANALYTICS')) {
                    console.log('Launch Event Snowplow');
                    window.snowplow('trackSelfDescribingEvent', {
                        event: {
                            schema: 'iglu:com.dgp/dv_user_interaction/jsonschema/1-0-0',
                            data: {}
                        },
                        context: [
                            {
                                schema: 'iglu:com.dgp/interaction_element/jsonschema/1-0-1',
                                data: {
                                    environment: 'browser', 
                                    element_type: 'button', 
                                    tracking_name: 'GoToStorePromoClick'
                                }
                            }
                        ]
                    });
                }    

                var storeUrl = '';
                var dldButton;
                var isUserLogged = NewtonAdapter.isUserLogged();
                
                if (storeType === 'Android') {
                    dldButton = document.getElementById('buttonAndroid');
                    if (BarneyConfig.get('GOOGLEPLAY_STORE_URL_INTENT') || BarneyConfig.get('GOOGLEPLAY_STORE_URL_HTTP') || BarneyConfig.get('MOA_API_URL_CONFIG_GPLAY') || BarneyConfig.get('GOOGLE_PLAY_STORE_URL_HTTP')) {
                        storeUrl = BarneyConfig.get('GOOGLEPLAY_STORE_URL_INTENT') || BarneyConfig.get('MOA_API_URL_CONFIG_GPLAY') || BarneyConfig.get('GOOGLEPLAY_STORE_URL_HTTP') || BarneyConfig.get('GOOGLE_PLAY_STORE_URL_HTTP');
                    }
                } else if (storeType === 'iOS') {
                    dldButton = document.getElementById('buttonIOS');
                    if (BarneyConfig.get('ITUNES_STORE_URL_HTTP') || BarneyConfig.get('MOA_API_URL_CONFIG_APPLESTORE')) {
                        storeUrl = BarneyConfig.get('ITUNES_STORE_URL_HTTP') || BarneyConfig.get('MOA_API_URL_CONFIG_APPLESTORE');
                    }
                }

                if (BarneyConfig.get('MFP_ENABLE') && ((isUserLogged && BarneyConfig.get('MFP_AUTOLOGIN_ENABLE')) || BarneyConfig.get('MFP_REDIRECTONLY_ENABLE'))) {  
                    NewtonAdapter.getTransientToken(function (err, resp) {
                        var ponyParams = {
                            data: {
                                return_url: $location.absUrl(),
                                cookieData: {
                                    cookie: {},
                                    newton: {
                                        session_id: NewtonAdapter.getSessionId() ? (NewtonAdapter.getSessionId()) : '',
                                        user_id: resp.token ? resp.token : resp
                                    }
                                }
                            }
                        };
                        var cookiesList = BarneyConfig.get('MFP_COOKIE_LIST').split(',');

                        for(var i = 0; i < cookiesList.length; i++){ //eslint-disable-line
                            console.log('cookie', cookiesList[i], JsStorage.get(cookiesList[i], { type: 'cookie' }));

                            if(JsStorage.get(cookiesList[i], { type: 'cookie' })){
                                ponyParams.data.cookieData.cookie[cookiesList[i]] = JsStorage.get(cookiesList[i], { type: 'cookie' });
                            }
                        }

                        var mfpParams = {
                            apikey: BarneyConfig.get('MOTIME_API_KEY') ? BarneyConfig.get('MOTIME_API_KEY') : BarneyConfig.get('MOA_API_KEY'),
                            contents_inapp: {
                                api_country: BarneyConfig.get('MFP_CONTENT_INAPP_API_COUNTRY') ? BarneyConfig.get('MFP_CONTENT_INAPP_API_COUNTRY') : BarneyConfig.get('API_COUNTRY'),
                                country: BarneyConfig.get('MFP_CONTENT_INAPP_TLD') ? BarneyConfig.get('MFP_CONTENT_INAPP_TLD') : BarneyConfig.get('TLD'),
                                fpnamespace: BarneyConfig.get('MFP_NAMESPACE') ? BarneyConfig.get('MFP_NAMESPACE') : BarneyConfig.get('SITE_PROFILE'),
                                b_test_id: BarneyConfig.get('B_TEST_ID'),
                                extData: {}
                            },
                            country: BarneyConfig.get('MFP_TLD') ? BarneyConfig.get('MFP_TLD') : BarneyConfig.get('TLD'),
                            expire: BarneyConfig.get('MFP_EXPIRE') ? BarneyConfig.get('MFP_EXPIRE') : 300
                        };

                        JsLogger.log('PONY', encodeURIComponent(JSON.stringify(ponyParams)), 'MFP', mfpParams);

                        if (BarneyConfig.get('ENABLE_NEWTON_USER')) {
                            console.log('ponyParams.data.cookieData.cookie');
                            ponyParams.data = btoa(JSON.stringify(ponyParams.data));
                        }

                        /*
                        *   storeUrl arrive with <appId> param,
                        *   in lite there is not appId, so at moment
                        *   we remove it
                        */

                        var appId = '';
                        storeUrl = storeUrl.replace(/<appId>/gi, appId);

                        Api.createPony(ponyParams).$promise
                            .then(function(data){
                                var ponyUrl = data.ponyUrl || '';
                                var domainUrl = BarneyConfig.get('DEST_DOMAIN');

                                if(domainUrl.indexOf(window.location.protocol) < 0){
                                    domainUrl = window.location.protocol + domainUrl;
                                }

                                mfpParams.contents_inapp.extData.ponyUrl = ponyUrl ? ponyUrl.replace('&', '') : '';
                                mfpParams.contents_inapp.extData.return_url = $location.absUrl();
                                mfpParams.contents_inapp.extData.domain = domainUrl;
                                mfpParams.contents_inapp = JSON.stringify(mfpParams.contents_inapp);

                                Api.set(mfpParams).$promise
                                .then(function(){

                                    //Appsflyer - imagazine
                                    if(BarneyConfig.get('APPSFLYER_ENABLED') && BarneyConfig.get('APPSFLYER_AUTOLOGIN')){
                                        if(storeUrl.indexOf('<pony>') !== -1){
                                            ponyUrl = ponyUrl.replace('&', '');
                                            storeUrl = storeUrl.replace('<pony>', encodeURIComponent(ponyUrl));
                                            storeUrl = storeUrl.replace('<redirectUrl>', encodeURIComponent($location.absUrl()));
                                            console.log('Appsflyer Store Url redirect to: ', storeUrl);
                                        }
                                    } else {  //MFP - pocoyohouse

                                        if(ponyUrl){
                                            ponyUrl = ponyUrl.replace('&_PONY=', '');
                                            storeUrl = storeUrl.replace('<cmp>', ponyUrl);
                                        }

                                        storeUrl = storeUrl.replace('<cmp>', ponyUrl);


                                        storeUrl = storeUrl + '&domain=' + encodeURIComponent(BarneyConfig.get('DEST_DOMAIN'));
                                    }


                                    console.log('PONY URL', ponyUrl, 'STORE URL', storeUrl);
                                    /*eslint-disable no-script-url*/
                                    if(storeType === 'Android' && dldButton !== undefined){
                                        dldButton.setAttribute('href', 'javascript:window.location.href=\'' + storeUrl + '\'');
                                        window.location.href = dldButton.getAttribute('href');
                                    }else{
                                        window.location.href = storeUrl;
                                    }

                                });
                            });
                    });
                } else {
                    window.location.href = storeUrl;
                }

            }
        };
    }
);



angular.module('lite').service('Utility',
function(BarneyConfig, $filter){

    return {
        getModulesTemplate: function(module){


            var prefix = BarneyConfig.get('IS_JENKINS_DEPLOYED') ? BarneyConfig.get('JS_PREFIX_APPS') + 'dist/' : BarneyConfig.get('JS_STAGE_URL');
            var tmpl = BarneyConfig.get('CUSTOM_MODULES')[module.toUpperCase()];
            if(tmpl){
                if($filter('userprop')('isLogged') && tmpl.LOGGED_TEMPLATE){
                    return prefix + tmpl.LOGGED_TEMPLATE;
                } else if(!$filter('userprop')('isLogged') && tmpl.UNLOGGED_TEMPLATE){
                    return prefix + tmpl.UNLOGGED_TEMPLATE;
                }
            } else {
                return [prefix, 'modules/', module, '/', module, '.html'].join();
            }
        }
    };
});

angular.module('lite').service('DateUtility',
function(){

    return {
        timestampIsExpired: function(expireTimestamp){

            var date = new Date();
            var timestamp = date.getTime() / 1000;

            if(timestamp > expireTimestamp) { return true; }

            return false;
        }
    };
});


angular.module('lite').filter('userprop', [
    'UserObj',
    function(user) {
        return function(prop, param, callback) {
            return user[prop](param, callback);
        };
    }
]);

angular.module('lite').filter('generateWelcomeMailUrl',
    ['BarneyConfig',
        function(Config){

            return function(url, key) {
                return url.replace(':' + key, objectToQueryString(Config.get(key)));
            };

            function objectToQueryString(object){
                var str = [];
                for(var p in object){
                    str.push(p + '=' + object[p]);
                }
                return str.join('&');
            }

        }
    ]
);

angular.module('lite').factory('MetaTitleManager',
    function(BarneyDict, BarneyConfig){

        var maskValue = '';
        var composedString = '';
        var titleMask = false;

        if(BarneyConfig.get('ENABLE_META_TITLE_MASK') && BarneyConfig.get('TITLE_MASK')){
            titleMask = BarneyConfig.get('TITLE_MASK');
        }

        var cosmicReplace = function(string){
            var str = string ? string.replace(/%\w+%/g, function(all) {
                while(all.indexOf('%') >= 0){
                    all = all.replace('%', '');
                }

                var dictKey = all.replace('DICTKEY_', '');
                return BarneyDict.get(dictKey);
            }) : '';

            console.log('*** COSMIC REPLACE ***', str);

            return str;
        };

        /*
            - metaTitleObject.noMaksValue: // default value if metatitle mask is disabled;
            - metaTitleObject.maskType: // should be home, list, zoom. If no value, takes default.
            - metaTitleObject.content: // value of the page (category, content ...)
        */

        return {
            getMetaTitle: function(metaTitleObject){
                if(titleMask){
                    switch(metaTitleObject.maskType) {
                    case 'home':
                        maskValue = titleMask[metaTitleObject.maskType];
                        maskValue = cosmicReplace(maskValue);

                        composedString = maskValue;
                        break;
                    case 'list':
                        maskValue = titleMask[metaTitleObject.maskType];
                        maskValue = cosmicReplace(maskValue);

                        composedString = maskValue.replace('<LIST_TITLE>', metaTitleObject.content);

                        break;
                    case 'zoom':
                        maskValue = titleMask[metaTitleObject.maskType];

                        maskValue = cosmicReplace(maskValue);
                        composedString = maskValue.replace('<CONTENT_TITLE>', metaTitleObject.content);

                        break;
                    default:
                        maskValue = titleMask.default;
                        maskValue = cosmicReplace(maskValue);

                        composedString = maskValue;

                        break;
                    }

                    return composedString;
                } else {
                    return metaTitleObject.noMaskValue;
                }
            }
        };
    }
);

Newton.immediateFixHistory();
angular.bootstrap(document, ['lite']);
